import React, { useState } from "react";
import ExchangeModal from "./ExchangeModal";

const Exchange = ({ selectedBooking }) => {
  const [show, setShow] = useState(false);

  function handleOpenExchange() {
    setShow(true);
  }

  return (
    <div>
      <div
        className="dropdown-item cursor-pointer"
        onClick={handleOpenExchange}
      >
        Exchange Ticket
      </div>
      {show && (
        <ExchangeModal
          show={show}
          onHide={() => setShow(false)}
          booking={selectedBooking}
        />
      )}
    </div>
  );
};

export default Exchange;
