import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import Loading from "../../../components/Loading";
import BookingRequestedModal from "./BookingRequestedModal";
import VoidPassengerModal from "../../../components/Modals/VoidPassengerModal";
import Header from "./Header";
import BookingBody from "./BookingBody";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCorporate } from "../../../redux2/corporate/corporateSlice";

const BookingList = ({
  bookings,
  user,
  currentPage,
  setCurrentPage,
  totalPage,
  bookingPerPage,
  setBookingPerPage,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [bookingRequestModal, setBookingRequestModal] = useState(false);
  const [bookingId, setBookingId] = useState(null);
  const [selectedBooking, setSelectedBooking] = useState(null);

  const [show, setShow] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  // const bookingPerPage = 5;

  const { corporate, isLoading } = useSelector((state) => state.corporate);
  const dispatch = useDispatch();

  let id = "638f0bd9eec9b98e572332af";

  useEffect(() => {
    dispatch(getCorporate(id));
  }, [dispatch, id]);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError(null);
    }
  }, [error]);

  // async function getTicket(booking) {
  //   setShow(true);
  //   // if (!booking?.flight?.retrievalPnr) return;

  //   // setLoading(true);
  //   // const res = await axios.post(API_URL + "flight/ticket", {
  //   //   PNR: booking?.flight?.PNR,
  //   // });

  //   // if (res.data?.AirTicketingRsp?.TicketFailureInfo) {
  //   //   setError(
  //   //     res?.data?.AirTicketingRsp?.TicketFailureInfo?._attributes?.Message
  //   //   );
  //   //   const data = {
  //   //     id: booking?._id,
  //   //     payment: "none",
  //   //     ticketNumber: "N/A",
  //   //     paymentStatus: "pending",
  //   //     status: "cancelled",
  //   //   };
  //   //   setLoading(false);
  //   //   dispatch(updateBooking(data));
  //   //   setTimeout(() => {
  //   //     navigate(0);
  //   //   }, 1000);
  //   // } else {
  //   //   const data = {
  //   //     id: booking?._id,
  //   //     payment: "wallet",
  //   //     serviceCharge: booking?.serviceCharge,
  //   //     systemFee: booking?.systemFee,
  //   //     ticketNumber:
  //   //       res.data?.AirTicketingRsp?.ETR?.Ticket?._attributes?.TicketNumber,
  //   //   };
  //   //   setLoading(false);
  //   //   dispatch(updateWallet(data));
  //   //   retrievePnr(booking?.flight);
  //   // }
  // }

  const handleLimitChange = (e) => {
    const newLimit = parseInt(e.target.value);
    setBookingPerPage(newLimit);
    searchParams.set("limit", newLimit);
    setSearchParams(searchParams);
  };

  const rateDifference = Math.max(
    (corporate?.galileoRate / corporate?.bankRate).toFixed(3),
    1
  );

  if (loading || isLoading) {
    return <Loading />;
  }

  return (
    <div className="table-responsive my-4">
      <div id="example2_wrapper" className="dataTables_wrapper no-footer ">
        <table
          id="exampl2"
          className="table card-table default-table display mb-4 dataTablesCard dataTable no-footer"
        >
          <thead>
            <Header />
          </thead>
          <tbody>
            <BookingBody
              bookings={bookings}
              currentPage={currentPage}
              bookingPerPage={bookingPerPage}
              user={user}
              setBookingId={setBookingId}
              setLoading={setLoading}
              setError={setError}
              setShow={setShow}
              setBookingRequestModal={setBookingRequestModal}
              bookingRequestModal={bookingRequestModal}
              setSelectedBooking={setSelectedBooking}
              rateDifference={rateDifference}
            />
          </tbody>
        </table>
        <div className="flex justify-between items-center my-5 ">
          <div className="flex-1 flex justify-center">
            <Pagination
              count={totalPage}
              page={currentPage}
              onChange={handlePageChange}
              siblingCount={4}
            />
          </div>
          <div className="flex items-center text-black gap-3 mr-3">
            <p lassName="mx-4">Limit</p>
            <select
              id="limit"
              className="form-select w-20"
              value={bookingPerPage}
              onChange={handleLimitChange}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
            </select>
          </div>
        </div>
      </div>

      {bookingRequestModal && (
        <BookingRequestedModal
          bookingRequestModal={bookingRequestModal}
          setBookingRequestModal={setBookingRequestModal}
          bookingId={bookingId}
        />
      )}
      {show && (
        <VoidPassengerModal
          show={show}
          onHide={() => setShow(false)}
          booking={selectedBooking}
          setLoading={setLoading}
          setError={setError}
        />
      )}
    </div>
  );
};

export default BookingList;
