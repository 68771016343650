import axios from "axios";
import API_URL from "../../../redux2";

export const airBooking = async (Booking, data, setLoading) => {
  let header = {
    service: "OrderCreate",
    ThirdpartyId: Booking.Query?.OrderItems?.ShoppingResponse?.Owner,
  };

  setLoading(true);
  // try {
  const res = await axios.post(API_URL + "/ndc/air-booking", {
    Booking,
    data,
    header,
  });

  return res.data;
  // } catch (error) {
  //   console.log(error);
  // } finally {
  //   setLoading(false);
  // }
};
