import axios from "axios";

import API_URL from "..";

const API_URL_EXCHANGE = API_URL + "exchange/";

/**
 * Auth
 */

// Register User
const register = async (userData) => {
  const res = await axios.post(API_URL_EXCHANGE + "auth/signup", userData);

  if (res.data) {
    localStorage.setItem("user", JSON.stringify(res.data.user));
  }

  return res.data;
};

// Login User
const auth = async () => {
  const res = await axios.post(API_URL_EXCHANGE + "authentication", {});
  if (res.data) {
    localStorage.setItem("token", JSON.stringify(res.data.access_token));
  }

  return res.data;
};

// Eligibility
const eligibility = async (token, pnr) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.get(
    API_URL_EXCHANGE + `eligibility?Locator=${pnr}`,
    config
  );

  return res.data;
};

const initiateExchange = async (token, pnr) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.post(
    API_URL_EXCHANGE + `initiateExchange?Locator=${pnr}`,
    {},
    config
  );

  return res.data;
};

const exchangeSearch = async (token, request) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.post(
    API_URL_EXCHANGE + `exchangeSearch`,
    request,
    config
  );

  return res.data;
};

const modifyOffer = async (token, request) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.post(
    API_URL_EXCHANGE + `exchangeOffers`,
    request,
    config
  );

  return res.data;
};

// Logout User
const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("expirationTime");
  // window.location.reload();
};

/**
 * User
 */

// user api
/**
 * User
 * @param {*} token
 * create user
 */

const exchangeApi = {
  register,
  auth,
  eligibility,
  initiateExchange,
  exchangeSearch,
  modifyOffer,
};

export default exchangeApi;
