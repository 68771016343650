export const convertToNumber = (num) => {
  if (num === null || num === undefined) return 0;
  // change ETB24900 to 24,900
  let price = num.replace(/,/g, "");
  // change ETB24900 to 24900
  price = price.replace(/[^0-9.]/g, "");
  // change 24900 to 24900.00
  price = price.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

  // price = price + num.substring(0, 3);

  return price;
};

export const removeCurrency = (num) => {
  if (num === null || num === undefined) return 0;

  // let price = num.replace(/,/g, "");

  let price = num.replace(/[^0-9.]/g, "");

  return price;
};

export const formatNumber = (num) => {
  if (num === "undefined") {
    return 0;
  }
  return num?.toString()?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};
