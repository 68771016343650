import axios from "axios";
import API_URL from "../../../redux2";

export const getNDCAirPricing = async (Pricing, setLoading) => {
  let header = {
    service: "FlightPrice",
    ThirdpartyId: Pricing.ShoppingResponseID.Owner,
  };

  setLoading(true);
  try {
    const res = await axios.post(API_URL + "/ndc/air-price", {
      Pricing,
      header,
    });

    return res.data;
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};
