const NDCShoppingReq = (req) => {
  const createTravelerArray = (count, type) =>
    Array.from({ length: count }, () => {
      return {
        PTC: { value: type },
        ...(type === "CHD" ? { Age: { Value: { value: 4 } } } : {}),
        ...(type === "INF" ? { Age: { Value: { value: 0 } } } : {}),
      };
    });

  const travelers = [
    ...createTravelerArray(req.adults, "ADT"),
    ...createTravelerArray(req.chd, "CHD"),
    ...createTravelerArray(req.inf, "INF"),
    ...createTravelerArray(req.labor, "LBR"),
  ].map((traveler) => ({ AnonymousTraveler: [traveler] }));

  const createOriginDestination = (origin, destination, date, key) => ({
    Departure: {
      AirportCode: { value: origin },
      Date: date,
    },
    Arrival: {
      AirportCode: { value: destination },
    },
    OriginDestinationKey: key,
  });

  const originDestinations = req.returnDate
    ? [
        createOriginDestination(
          req.origin,
          req.destination,
          req.departureDate,
          "OD1"
        ),
        createOriginDestination(
          req.destination,
          req.origin,
          req.returnDate,
          "OD2"
        ),
      ]
    : [
        createOriginDestination(
          req.origin,
          req.destination,
          req.departureDate,
          "OD1"
        ),
      ];

  const preference = {
    FarePreferences: {
      Types: {
        Type: [{ Code: "PUBL" }, { Code: "PVT" }],
      },
    },
    ...(req.cabin && {
      CabinPreferences: {
        CabinType: [{ Code: req.cabin }],
      },
    }),
  };

  const responseParameters = {
    ShopResultPreference: "OPTIMIZED",
    SortOrder: [
      { Order: "ASCENDING", Parameter: "STOP" },
      { Order: "ASCENDING", Parameter: "PRICE" },
      { Order: "ASCENDING", Parameter: "DEPARTURE_TIME" },
    ],
  };

  return {
    // Travelers: { Traveler: [{ AnonymousTraveler: travelers }] },
    Travelers: { Traveler: travelers },
    CoreQuery: {
      OriginDestinations: { OriginDestination: originDestinations },
    },
    Preference: preference,
    ResponseParameters: responseParameters,
  };
};

const headers = {
  "Content-Type": "application/json",
  Authorization:
    "Bearer eyJraWQiOiJiZjA1ODMxYS0zNzFmLTRlY2UtYWExYS04YTUyNGRlZmMwMWMiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJzdGVybGluZ2luX2FwaXRlc3R1c2VyIiwiYXVkIjoic3Rlcmxpbmdpbl9hcGl0ZXN0dXNlciIsIm5iZiI6MTcyMDA3NzY1MywiZ3JhbnQtdHlwZSI6ImNsaWVudF9jcmVkZW50aWFscyIsImRlZmF1bHQtb2ZmaWNlIjoiT0ZGMTAyIiwic2NvcGUiOlsiYXBpIl0sImlzcyI6Imh0dHA6Ly9hdXRoLXNlcnZpY2UiLCJhY2Nlc3NSaWdodHMiOlsib2ZmZXIucmVwcmljZW9yZGVyIiwib2ZmZXIucmVmdW5kcXVvdGUiLCJvZmZlci5yZXNob3BvcmRlciIsIm9mZmVyLnNob3AiLCJvZmZlci5wcmljZSIsIm9mZmVyLmxpc3RzZXJ2aWNlcyIsIm9mZmVyLmxvYWRleGNoYW5nZXJhdGUiLCJvZmZlci5saXN0c2VhdHMiLCJvZmZlci5haXJhdmFpbGFiaWxpdHkiLCJvZmZlci5mYXJlcnVsZXMiLCJvZmZlci5zZXJ2aWNlaW5mbyJdLCJvZmZpY2UiOlsiT0ZGMTAyIl0sImV4cCI6MTcyMDEyMDg1MywiaWF0IjoxNzIwMDc3NjUzLCJVc2VyVHlwZSI6IkFQSS1Vc2VyIn0.BHWBrV83gz_3e3Wn7e6GdolzpoSFpY9PN8-TuQUcJD3KrunOLU8HTFhQCpvabdq5GcspEl5WnSadlVNfX6VIsjhuROHcPHPV4buOIBGXuO8cigEjXcxm03P0lU9IG_sU10QUm8KNu6pRdkTQ2_gynDqL_hGFKrqF87R9ifscZIoZ-Iz0U7newKGjjLLsuDWpd2zYXYWBRBvPjmMc2NYazchc3MRtT3f5ivC_RX_inwZrSFA7tMNvXn--JJp5LNkP3rwI5_m508-vrSIFdUsDyZtlqJvnyeV3Mhm0ugPoy8R3Rf7cBXhNKnYERxm8nB0F3Pz_J44iS2MNbutM6dkEyw",
  "Accept-Encoding": "gzip, deflate, br",
  officeId: "OFF102",
  ThirdpartyId: "",
  service: "AirShopping",
  Accept: "*/*",
};

const NDC_API = "https://api.stage.verteil.com/entrygate/rest/request:";

export { NDCShoppingReq, headers, NDC_API };
