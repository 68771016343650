import moment from "moment";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import convertNumbThousand from "../../utils/convertNumbThousand";
import ButtonPrimary from "../Button/ButtonPrimary";
import { getCarrierInfo, getCarrierLogo } from "../../utils/flightInfo";
import customTime from "../../utils/customTime";

const ReturnTrip = ({
  className = "",
  flights,
  flightDetail,
  flight,
  query,
  setTotalPrice,
  departData,
  charge,
  classType,
  bookingRef,
  currencyStates,
  rateDifference,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  if (!flights && flight && flightDetail) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32"></div>
      </div>
    );
  }

  // if (departData) {
  //   if (departData instanceof Array) {
  //     qryStr = Object.keys(departData)
  //       // make it array of string
  //       .map((key) => `${departData[key]._attributes.Key}`);
  //     // .join("&");
  //   } else {
  //     qryStr = departData._attributes.Key;
  //   }
  // }

  const getCarrier = (segment) => {
    let code = flights.find(
      (flight) => flight._attributes.Key === segment._attributes.Key
    )._attributes.Carrier;
    return getCarrierInfo(code)?.Name || code;
    // return CarrierList.find((carrier) => carrier.Code === code)?.Name || code;
  };

  const getAirSegment = (segment) => {
    // console.log(segment._attributes.Key);
    return flights.find(
      (flight) => flight?._attributes?.Key === segment?._attributes?.Key
    )?._attributes;
  };

  const getLogo = (segment) => {
    let code = flights?.find(
      (flight) => flight._attributes.Key === segment._attributes.Key
    )._attributes.Carrier;
    return getCarrierLogo(code);
    // return CarrierList.find((carrier) => carrier.Code === code)?.Logo;
  };

  const calculateTime = (departure, arrival) => {
    let time = moment(departure).diff(moment(arrival), "minutes");

    let hours = Math.floor(time / 60);
    let minutes = time % 60;

    return `${hours}h ${minutes}m`;
  };

  const getCabinClass = (segment) => {
    return flight?.AirPricingInfo?.BookingInfo?.find(
      (flight) => flight._attributes.SegmentRef === segment._attributes.Key
    )._attributes.CabinClass;
  };

  let flightData = [];

  if (departData instanceof Array) {
    departData.map((item) => {
      flightData.push(getAirSegment(item));
    });
  } else {
    flightData.push(getAirSegment(departData));
  }

  function handleNavigate(rtn) {
    let dptData = [];
    let rtnData = [];

    if (departData instanceof Array) {
      departData.map((seg, index) => {
        dptData.push({
          ...getAirSegment(seg),
          classType:
            flight?.AirPricingInfo?.BookingInfo?.[index]?._attributes
              ?.BookingCode,
        });
      });
    } else {
      dptData.push({
        ...getAirSegment(departData),
        classType:
          flight?.AirPricingInfo?.BookingInfo?.[0]?._attributes?.BookingCode,
      });
    }

    if (rtn instanceof Array) {
      rtn.map((seg, index) => {
        rtnData.push({
          ...getAirSegment(seg),
          classType:
            flight?.AirPricingInfo?.BookingInfo?.[
              flight?.AirPricingInfo?.BookingInfo.length - ++index
            ]?._attributes?.BookingCode,
        });
      });
    } else {
      rtnData.push({
        ...getAirSegment(rtn),
        classType:
          flight?.AirPricingInfo?.BookingInfo?.[
            flight?.AirPricingInfo?.BookingInfo.length - 1
          ]?._attributes?.BookingCode,
      });
    }

    navigate(`/flight-detail2?${query}`, {
      state: {
        segmentData: dptData,
        returnData: rtnData,
        charges: charge,
        classType: classType,
        queryURL: query,
        bookingRef: bookingRef,
        segmentConnection: flight?.Connection,
        returnClassType:
          flight?.AirPricingInfo?.BookingInfo ??
          flight?.AirPricingInfo?.[0]?.BookingInfo,
        currency: currencyStates,
        rateDifference,
      },
    });
  }

  const selectBtnLink = (price, seg) => {
    return (
      <ButtonPrimary
        className=""
        onClick={() => handleNavigate(flight.Journey[1].AirSegmentRef)}
      >
        Select
      </ButtonPrimary>
    );
  };

  function totalPrice(price) {
    const airPrice = parseFloat(price?.toString().slice(3));

    let result =
      rateDifference > 1 ? (airPrice * rateDifference).toFixed(2) : airPrice;

    result = parseFloat(result) + charge;
    return convertNumbThousand(result);
  }

  const renderDetailTop = (seg, stat, btnVisible, index) => {
    return (
      <div>
        <div className="flex flex-col md:flex-row ">
          <div className="w-24 md:w-20 lg:w-24 flex-shrink-0 md:pt-7">
            <img
              src={getLogo(
                flight.Journey[1].AirSegmentRef instanceof Array
                  ? flight.Journey[1].AirSegmentRef[1]
                  : flight.Journey[1].AirSegmentRef
              )}
              className="w-15"
              alt=""
            />
          </div>
          <div className="flex my-5 md:my-0 lg:w-72">
            <div className="flex-shrink-0 flex flex-col items-center py-2">
              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
              <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
            </div>
            <div className="ml-4 space-y-10 text-sm">
              {stat ? (
                <>
                  <div className="flex flex-col space-y-1">
                    <span className=" text-neutral-500 dark:text-neutral-400">
                      {/* Monday, August 12 · 10:00 */}
                      <>{customTime(getAirSegment(seg).DepartureTime)}</>
                    </span>
                    <span className=" font-semibold text-black">
                      {getAirSegment(seg).Origin}
                    </span>
                  </div>
                  <div className="flex flex-col space-y-1">
                    <span className=" text-neutral-500 dark:text-neutral-400">
                      {/* Monday, August 16 · 10:00 */}
                      {customTime(getAirSegment(seg).ArrivalTime)}
                    </span>
                    <span className=" font-semibold text-black">
                      {getAirSegment(seg).Destination}
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex flex-col space-y-1">
                    <span className=" text-neutral-500 dark:text-neutral-400">
                      {customTime(getAirSegment(seg).DepartureTime)}
                    </span>
                    <span className=" font-semibold text-black">
                      {getAirSegment(seg).Origin}
                    </span>
                  </div>
                  <div className="flex flex-col space-y-1">
                    <span className=" text-neutral-500 dark:text-neutral-400">
                      {customTime(getAirSegment(seg).ArrivalTime)}
                    </span>
                    <span className=" font-semibold text-black">
                      {/* {getCarrier(seg)}  */}
                      {getAirSegment(seg).Destination}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="border-l border-neutral-200 dark:border-neutral-700 md:mx-6 lg:mx-10"></div>
          {stat ? (
            <ul className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2">
              <li>
                Flight time:{" "}
                <span>
                  {calculateTime(
                    getAirSegment(seg).ArrivalTime,
                    getAirSegment(seg).DepartureTime
                  )}
                </span>
              </li>
              <li>Flight Class: {getCabinClass(seg)}</li>
              <li>Flight Number: {getAirSegment(seg).FlightNumber}</li>
              <li>Equipment: {getAirSegment(seg).Equipment}</li>
              <li>
                Booking Class:{" "}
                {flight?.AirPricingInfo?.[index]?.BookingInfo?.[index]
                  ?._attributes?.BookingCode ||
                  flight?.AirPricingInfo?.BookingInfo?.[index]?._attributes
                    ?.BookingCode}
              </li>

              {/* <li>ANA · Business class · Boeing 787 · NH 847</li> */}
            </ul>
          ) : (
            <ul className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2">
              <li>
                Trip time:{" "}
                <span>
                  {calculateTime(
                    getAirSegment(seg).ArrivalTime,
                    getAirSegment(seg).DepartureTime
                  )}
                </span>
              </li>
              <li>Flight: ET {getAirSegment(seg).FlightNumber} </li>
              <li>Equipment: {getAirSegment(seg).Equipment}</li>
              <li>
                Flight Class:{" "}
                {
                  flight.AirPricingInfo.BookingInfo?.[1]?._attributes
                    ?.CabinClass
                }
              </li>
              <li>
                Booking Class:{" "}
                {flight?.AirPricingInfo?.[0]?.BookingInfo?.[1]?._attributes
                  ?.BookingCode ||
                  flight?.AirPricingInfo?.BookingInfo?.[1]?._attributes
                    ?.BookingCode}
              </li>
              {/* <li>ANA · Business class · Boeing 787 · NH 847</li> */}
            </ul>
          )}
          {stat ? (
            btnVisible && (
              <>
                <div className="md:mx-6 lg:mx-48 "></div>
                <div className="px-6 py-2 mt-auto">
                  {selectBtnLink(flight?._attributes.TotalPrice, seg)}
                </div>
              </>
            )
          ) : (
            <>
              <div className="md:mx-6 lg:mx-48 "></div>
              <div className="px-6 py-2 mt-auto">
                {selectBtnLink(flight?._attributes.TotalPrice, seg)}
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  const renderDetail = () => {
    if (!isOpen) return null;
    return (
      <div className="p-4 md:p-8 border border-neutral-200 dark:border-neutral-700 rounded-2xl ">
        {flight.Journey[1].AirSegmentRef instanceof Array ? (
          flight.Journey[1].AirSegmentRef.map((seg, index) => (
            <>
              {renderDetailTop(
                seg,
                true,
                index === flight.Journey[1].AirSegmentRef.length - 1,
                index
              )}
              {index === flight.Journey[1].AirSegmentRef.length - 1 ? null : (
                <div className="my-7 md:my-10 space-y-5 md:pl-24">
                  <div className="border-t border-neutral-200 dark:border-neutral-700" />
                  <div className="text-neutral-700 dark:text-neutral-300 text-sm md:text-base">
                    Transit time:{" "}
                    {calculateTime(
                      getAirSegment(flight.Journey[1].AirSegmentRef[index + 1])
                        .DepartureTime,
                      getAirSegment(flight.Journey[1].AirSegmentRef[index])
                        .ArrivalTime
                    )}
                  </div>
                  <div className="border-t border-neutral-200 dark:border-neutral-700" />
                </div>
              )}
            </>
          ))
        ) : (
          <>{renderDetailTop(flight.Journey[1].AirSegmentRef, false)}</>
        )}

        {/* {flight.Journey[1].AirSegmentRef instanceof Array && (
          <>
            <div className="my-7 md:my-10 space-y-5 md:pl-24">
              <div className="border-t border-neutral-200 dark:border-neutral-700" />
              <div className="text-neutral-700 dark:text-neutral-300 text-sm md:text-base">
                Transit time: 15 hours 45 minutes - Bangkok (BKK)
              </div>
              <div className="border-t border-neutral-200 dark:border-neutral-700" />
            </div>
            {renderDetailTop(true)}
          </>
        )} */}
      </div>
    );
  };

  // console.log(flight.Journey[1]);

  return (
    <div
      className={`nc-FlightCardgroup p-4 sm:p-6 relative bg-white dark:bg-neutral-900 border border-neutral-100
     dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`}
      data-nc-id="FlightCard"
      onClick={() => setIsOpen(!isOpen)}
    >
      <div
        className={` sm:pr-20 relative  ${className}`}
        data-nc-id="FlightCard"
      >
        {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
        <a href="##" className="absolute inset-0" />

        <span
          className={`absolute right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${
            isOpen ? "transform -rotate-180" : ""
          }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <i className="text-xl las la-angle-down"></i>
        </span>

        <div className="flex  flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
          {/* LOGO IMG */}
          <div className="w-24 lg:w-32 flex-shrink-0">
            <img
              src={getLogo(
                flight.Journey[1].AirSegmentRef instanceof Array
                  ? flight.Journey[1].AirSegmentRef[1]
                  : flight.Journey[1].AirSegmentRef
              )}
              className="w-16"
              alt=""
            />
          </div>

          {/* FOR MOBILE RESPONSIVE */}
          <div className="block lg:hidden space-y-1">
            <div className="flex font-semibold">
              <div>
                <span>
                  {customTime(
                    getAirSegment(
                      flight.Journey[1].AirSegmentRef instanceof Array
                        ? flight.Journey[1].AirSegmentRef[0]
                        : flight.Journey[1].AirSegmentRef
                    ).DepartureTime
                  )}{" "}
                </span>

                <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                  {
                    getAirSegment(
                      flight.Journey[1].AirSegmentRef instanceof Array
                        ? flight.Journey[1].AirSegmentRef[1]
                        : flight.Journey[1].AirSegmentRef
                    ).Origin
                  }
                </span>
              </div>
              <span className="w-12 flex justify-center">
                <i className=" text-2xl las la-long-arrow-alt-right"></i>
              </span>
              <div>
                <span>
                  <span>
                    {customTime(
                      getAirSegment(
                        flight.Journey[1].AirSegmentRef instanceof Array
                          ? flight.Journey[1].AirSegmentRef[
                              flight.Journey[1].AirSegmentRef.length - 1
                            ]
                          : flight.Journey[1].AirSegmentRef
                      ).ArrivalTime
                    )}{" "}
                  </span>
                </span>
                <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                  {
                    getAirSegment(
                      flight.Journey[1].AirSegmentRef instanceof Array
                        ? flight.Journey[1].AirSegmentRef[1]
                        : flight.Journey[1].AirSegmentRef
                    ).Destination
                  }
                </span>
              </div>
              <div className="w-24"></div>
              <div className="text-sm text-black font-normal mt-0.5">
                <span className="VG3hNb">
                  {flight.Journey[1].AirSegmentRef instanceof Array
                    ? flight.Journey[1].AirSegmentRef.length - 1 + " Stops"
                    : "Direct"}
                </span>
              </div>
            </div>
          </div>

          {/* TIME - NAME */}
          <div className="hidden lg:block  min-w-[150px] flex-[4] ">
            <div className="font-medium text-lg text-black">
              {customTime(
                getAirSegment(
                  flight.Journey[1].AirSegmentRef instanceof Array
                    ? flight.Journey[1].AirSegmentRef[0]
                    : flight.Journey[1].AirSegmentRef
                ).DepartureTime
              ) +
                " - " +
                customTime(
                  getAirSegment(
                    flight.Journey[1].AirSegmentRef instanceof Array
                      ? flight.Journey[1].AirSegmentRef[
                          flight.Journey[1].AirSegmentRef.length - 1
                        ]
                      : flight.Journey[1].AirSegmentRef
                  ).ArrivalTime
                )}
            </div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {/* {data.airlines.name} */}
              {getCarrier(
                flight.Journey[1].AirSegmentRef instanceof Array
                  ? flight.Journey[1].AirSegmentRef[0]
                  : flight.Journey[1].AirSegmentRef
              )}
            </div>
          </div>

          {/* TIME */}
          <div className="hidden lg:block flex-[4] whitespace-nowrap">
            <div className="font-medium text-lg text-black">
              {
                getAirSegment(
                  flight.Journey[1].AirSegmentRef instanceof Array
                    ? flight.Journey[1].AirSegmentRef[0]
                    : flight.Journey[1].AirSegmentRef
                ).Origin
              }{" "}
              -{" "}
              {
                getAirSegment(
                  flight.Journey[1].AirSegmentRef instanceof Array
                    ? flight.Journey[1].AirSegmentRef[
                        flight.Journey[1].AirSegmentRef.length - 1
                      ]
                    : flight.Journey[1].AirSegmentRef
                ).Destination
              }
            </div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {/* {moment(
                getAirSegment(
                  flight.Journey[1].AirSegmentRef instanceof Array
                    ? flight.Journey[1].AirSegmentRef[0]
                    : flight.Journey[1].AirSegmentRef
                ).ArrivalTime
              ).diff(
                getAirSegment(
                  flight.Journey[1].AirSegmentRef instanceof Array
                    ? flight.Journey[1].AirSegmentRef[0]
                    : flight.Journey[1].AirSegmentRef
                ).DepartureTime,
                "hours"
              )}{" "}
              hours{" "}
              {moment(
                getAirSegment(
                  flight.Journey[1].AirSegmentRef instanceof Array
                    ? flight.Journey[1].AirSegmentRef[0]
                    : flight.Journey[1].AirSegmentRef
                ).ArrivalTime
              ).diff(
                getAirSegment(
                  flight.Journey[1].AirSegmentRef instanceof Array
                    ? flight.Journey[1].AirSegmentRef[0]
                    : flight.Journey[1].AirSegmentRef
                )?.DepartureTime,
                "minutes"
              ) % 60}{" "}
              minutes */}
              {moment(
                getAirSegment(
                  flight.Journey[1].AirSegmentRef instanceof Array
                    ? flight.Journey[1].AirSegmentRef[
                        flight.Journey[1].AirSegmentRef.length - 1
                      ]
                    : flight.Journey[1].AirSegmentRef
                ).ArrivalTime
              ).diff(
                getAirSegment(
                  flight.Journey[1].AirSegmentRef instanceof Array
                    ? flight.Journey[1].AirSegmentRef[0]
                    : flight.Journey[1].AirSegmentRef
                ).DepartureTime,
                "hours"
              )}{" "}
              hours{" "}
              {moment(
                getAirSegment(
                  flight.Journey[1].AirSegmentRef instanceof Array
                    ? flight.Journey[1].AirSegmentRef[
                        flight.Journey[1].AirSegmentRef.length - 1
                      ]
                    : flight.Journey[1].AirSegmentRef
                ).ArrivalTime
              ).diff(
                getAirSegment(
                  flight.Journey[1].AirSegmentRef instanceof Array
                    ? flight.Journey[1].AirSegmentRef[0]
                    : flight.Journey[1].AirSegmentRef
                )?.DepartureTime,
                "minutes"
              ) % 60}{" "}
              minutes
            </div>
          </div>

          {/* TYPE */}
          <div className="hidden lg:block flex-[4] whitespace-nowrap">
            <div className="font-medium text-lg text-black">
              {flight.Journey[1].AirSegmentRef instanceof Array
                ? flight.Journey[1].AirSegmentRef.length -
                  1 +
                  " Stops " +
                  `(${
                    getAirSegment(flight.Journey[1]?.AirSegmentRef?.[0])
                      .Destination +
                    (flight.Journey[1].AirSegmentRef.length - 1 > 1
                      ? ", " +
                        getAirSegment(flight.Journey[1]?.AirSegmentRef?.[1])
                          .Destination
                      : "")
                  })`
                : "Direct"}
            </div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {/* calculate total stop time */}

              {/* 2 hours 15 minutes BKK || Unknown yet */}
            </div>
          </div>

          {/* PRICE */}
          <div className="flex-[4] whitespace-nowrap sm:text-right">
            <div>
              <span className="text-xl font-semibold text-secondary-6000">
                {totalPrice(flight._attributes.TotalPrice)}{" "}
                {flight._attributes.TotalPrice.substring(0, 3)}
              </span>
            </div>
            <div className="text-xs sm:text-sm text-neutral-500 font-normal mt-0.5">
              {/* <p>Round-trip</p> */}
              ET-
              {
                getAirSegment(
                  flight.Journey[1].AirSegmentRef instanceof Array
                    ? flight.Journey[1].AirSegmentRef[0]
                    : flight.Journey[1].AirSegmentRef
                ).FlightNumber
              }
            </div>
          </div>
        </div>
      </div>

      {/* DETAIL */}
      {renderDetail()}
    </div>
  );
};

export default ReturnTrip;
