import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

import ShoppingItems from "./ShoppingItems";
import { PricingReq } from "../AirPricing/PricingReq";
import { getNDCAirPricing } from "../Services/AirPricing";
import { groupDataListByReference, groupOffersByReference } from "../helpers";
import { toast } from "react-toastify";
// import dataSet from "./Add-dxb-round.json";

const Shopping = ({ dataSet, setLoading, query }) => {
  const [selectedOffer, setSelectedOffer] = useState(null);

  const navigate = useNavigate();

  const groupedOffers = groupOffersByReference(
    dataSet?.OffersGroup?.AirlineOffers?.[0].AirlineOffer
  );

  let Metadata = dataSet?.Metadata?.Other?.OtherMetadata;

  const onSelect = async (offer, oneWayLength) => {
    const flightList =
      offer.PricedOffer.OfferPrice[0].RequestedDate.Associations?.map(
        (item) => {
          return item.ApplicableFlight.FlightReferences.value;
        }
      );
    const Pricing = PricingReq(offer, Metadata, dataSet, flightList);

    // console.log(JSON.stringify(Pricing, null, 2));

    const response = await getNDCAirPricing(Pricing, setLoading);

    if (response?.DataLists) {
      navigate(`/ndc-pricing?${query}`, {
        state: { data: { response, oneWayLength, queryURL: query } },
      });
    }

    if (response?.Errors) {
      toast.error(response.Errors.Error[0].value);
      return;
    }
  };

  return (
    <div className="flex flex-col gap-6 ">
      {dataSet && (
        <Fragment>
          <h1 className="text-2xl font-bold mx-2">NDC</h1>
          {dataSet.Errors && !dataSet.DataLists
            ? dataSet.Errors?.Error?.map((error, index) => (
                <div key={index} className="text-red-500 mx-2">
                  * {error.value}
                </div>
              ))
            : Object?.keys(groupedOffers).map((reference, index) => {
                const offers = groupedOffers[reference];
                let segment = groupDataListByReference(reference, dataSet);
                let oneWayLength =
                  offers?.[0]?.PricedOffer?.Associations[0].ApplicableFlight
                    .FlightSegmentReference.length;

                return (
                  <div key={index} onClick={() => setSelectedOffer(reference)}>
                    <ShoppingItems
                      offer={offers}
                      segment={segment}
                      oneWayLength={oneWayLength}
                      dataList={dataSet?.DataLists}
                      isSelected={selectedOffer === reference}
                      Metadata={dataSet?.Metadata?.Other?.OtherMetadata}
                      onSelect={onSelect}
                    />
                  </div>
                );
              })}
        </Fragment>
      )}
    </div>
  );
};

export default Shopping;
