import React, { Fragment, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { Tab } from "@headlessui/react";
import moment from "moment";
import ReactToPrint, { useReactToPrint } from "react-to-print";

import { getBookingById } from "../../../redux2/booking/bookingSlice";
import convertNumbThousand from "../../../utils/convertNumbThousand";
import NcImage from "../../NcImage/NcImage";
import { currencyType, getCarrierLogo } from "../../../utils/flightInfo";
import { DirectPrint } from "../../Ticket/DirectPrint";
import ButtonPrimary from "../../Button/ButtonPrimary";
import { NDCRetrieveReq } from "../NDCRequest/NDCRequest";
import axios from "axios";
import API_URL from "../../../redux2";
import { NDCTicketInfo } from "../Ticket/component/NDCTicketInfo";

const DonePage = ({ className = "" }) => {
  const [loading, setLoading] = React.useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    // airReservation,
    // ticket,
    // travelers,
    // charges,
    // serviceCharge,
    // airPrice,
    // currency,
    // reservation,
    booking: response,
  } = location.state;
  const { booking } = useSelector((state) => state.booking);

  // let travelers = response?.DataLists.AnonymousTravelerList?.AnonymousTraveler,
  let travelers = response?.Response?.Passengers?.Passenger,
    airReservation =
      response?.Response?.DataLists?.FlightSegmentList?.FlightSegment,
    tickets = response?.Response.TicketDocInfos.TicketDocInfo,
    charges = 0,
    serviceCharge = 0,
    airPrice = response?.Response.Order,
    currency = "",
    reservation = {};

  let passengerList = [];
  if (travelers instanceof Array) {
    passengerList = travelers?.map((passenger) => {
      return [
        passenger?.BookingTravelerName?._attributes,
        passenger?.AirSeatAssignment,
      ];
    });
  } else {
    passengerList.push([
      travelers?.BookingTravelerName?._attributes,
      travelers?.AirSeatAssignment,
    ]);
  }

  const getPassengerByRef = (ref) => {
    const passenger = travelers.find((item) => item.ObjectKey === ref);
    if (!passenger) return "Passenger not found";
    const { Given, Surname } = passenger.Name;
    return `${Given[0].value} ${Surname.value}`;
  };

  const data = response?.Response.Order[0].OrderID;

  const retrieve = NDCRetrieveReq(data);

  useEffect(() => {
    async function getRetrieveOrder() {
      if (retrieve) {
        let header = {
          service: "OrderRetrieve",
          ThirdpartyId: response.Response.Order[0].OrderID?.Owner,
        };

        try {
          const res = await axios.post(API_URL + "/ndc/order-retrieve", {
            Retrieve: retrieve,
            header,
          });

          return res.data;
        } catch (error) {
          console.log(error);
        }
      }
    }
    getRetrieveOrder();
  }, []);

  console.log(JSON.stringify(retrieve));

  const { id } = useParams();

  const query = new URLSearchParams(location.search);
  const adults = query.get("adults");
  const children = query.get("children");
  const infants = query.get("infants");
  const destination = query.get("destination");

  useEffect(() => {
    dispatch(getBookingById(id));
  }, [dispatch, id]);

  const componentRef = useRef(null);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, []);

  // let ticketData = ticket?.ETR?.Ticket?._attributes.TicketNumber;
  // let ticketData = tickets?.ETR?.Ticket;
  let ticketNumbers = [];

  ticketNumbers = tickets.map((item) => {
    return item;
  });

  let basePrice = 0;
  airPrice.map((item) => {
    basePrice += item.TotalOrderPrice.SimpleCurrencyPrice.value;
    // *
    // item.RequestedDate.Associations[0].AssociatedTraveler.TravelerReferences
    //   .length || 1;
  });

  function totalPrice() {
    // let airPrice =
    //   airReservation.AirPricingInfo._attributes.TotalPrice.toString().slice(3);
    let totalPrice = basePrice;
    totalPrice = parseFloat(totalPrice);

    let result =
      totalPrice +
      parseFloat(booking?.commission || 0) +
      charges +
      parseFloat(serviceCharge || 0);
    return convertNumbThousand(result);
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    // onBeforeGetContent: () => ({
    //   styles: "@media print { body { visibility: hidden; } }",
    //   documentTitle: `Ticket-`,
    // }),
    // onBeforePrint: () => ({
    //   documentTitle: `Ticket-`,
    // }),
  });

  const renderContent = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-4 sm:p-6 p-6 xl:p-8 shadow">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Congratulation 🎉
        </h2>
        <p className="text-neutral-500">
          Your flight has been booked successfully. Please check your email for
          the ticket.
        </p>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* ------------------------ */}
        <div className="space-y-6 mb-4">
          <h3 className="text-2xl font-semibold">Your flight</h3>
          <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 rounded p-6 sm:p-6 xl:p-8 shadow mb-5">
            <div className="flex flex-col sm:flex-row sm:items-center">
              <div className="flex-shrink-0 w-full sm:w-40">
                <NcImage
                  src={getCarrierLogo(
                    airReservation?.[0]?.MarketingCarrier?.AirlineID?.value
                  )}
                />
              </div>
              <div className="py-5 sm:px-5 space-y-3">
                <span className="text-lg text-neutral-500 font-semibold">
                  <h1>
                    {airReservation?.[0]?.MarketingCarrier?.AirlineID?.value}
                  </h1>
                </span>

                {airReservation?.map((segment, index) => (
                  <div key={index}>
                    <div className="text-lg text-neutral-500 font-semibold line-clamp-1">
                      <h1>
                        {segment.Departure?.AirportCode.value} -{" "}
                        {segment.Arrival?.AirportCode.value}
                      </h1>
                    </div>
                    <span className="text-base font-medium mt-1 block">
                      {moment(segment.Departure?.Date).format("DD MMM YYYY")} -
                      {moment(segment.Arrival?.Date).format("DD MMM YYYY")}
                    </span>
                    <span className="text-base font-medium mt-1 block">
                      {segment.Departure?.Time} - {segment.Arrival?.Time}
                    </span>
                  </div>
                ))}
                {/* <div className="flex flex-row justify-start gap-3">
                  <span className="block  text-sm text-neutral-500">
                    {travelerTypeCounts?.ADT > 3} Adult
                  </span>
                  {travelerTypeCounts?.CHD > 0 && (
                    <span className="block  text-sm text-neutral-500">
                      {travelerTypeCounts?.CHD + " Children"}
                    </span>
                  )}
                  <span className="block  text-sm text-neutral-500">
                    {travelerTypeCounts?.INF > 0 &&
                      travelerTypeCounts?.INF + " Infants"}
                  </span>
                </div> */}
                <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
              </div>
            </div>
            <div className="flex flex-col space-y-4">
              <h3 className="text-2xl font-semibold">Price detail</h3>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>Price</span>
                <span>
                  {airPrice[0].TotalOrderPrice.SimpleCurrencyPrice.Code}{" "}
                  {basePrice}
                </span>
              </div>
              {/* <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>Service charge</span>
                <span>
                  {charges !== 0 ? charges : serviceCharge}{" "}
                  {currencyType(currency)}
                </span>
              </div>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>Agent Fee</span>
                <span>
                  {booking?.commission || 0} {currencyType(currency)}
                </span>
              </div> */}
              <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
              <div className="flex justify-between font-bold text-xl text-black">
                <span>Total</span>
                <span>
                  {airPrice[0].TotalOrderPrice.SimpleCurrencyPrice.Code}{" "}
                  {totalPrice()}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Flight detail</h3>
          <div className="flex flex-col space-y-4">
            <div className="flex flex-col text-neutral-600 dark:text-neutral-300 p-4 bg-white dark:bg-neutral-800 rounded-lg shadow-md">
              <div className="flex items-center mb-4">
                <span className="flex-1 text-lg font-semibold text-neutral-900 dark:text-neutral-100">
                  Ticket code
                </span>
              </div>
              <div className="flex flex-col space-y-4">
                {tickets?.map((item, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between border-b border-neutral-200 dark:border-neutral-700 pb-2"
                  >
                    <span className="font-normal text-neutral-700 dark:text-neutral-400">
                      {++index}. {getPassengerByRef(item.PassengerReference[0])}
                    </span>
                    <span className="font-semibold text-main-900 dark:text-main-300">
                      {item?.TicketDocument[0]?.TicketDocNbr}
                    </span>
                  </div>
                ))}
              </div>
            </div>

            {/* <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Payment method</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {booking?.payment}
              </span>
            </div> */}
            {booking?.payment === "credit" && (
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span className="flex-1">Corporate Name</span>
                <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                  {booking?.corporateName}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="pt-5">
          <h3 className="text-2xl font-semibold">Ticket Purchasing Complete</h3>
          {/* <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"> */}
          <Tab.Group>
            <Tab.List className="flex my-5">
              <Tab as={Fragment}>
                {({ selected }) => (
                  <Link to="#" className="hover:text-gray-400">
                    <button className="p-3 ml-5 mb-3 text-black hover:border rounded">
                      <div className="ml-5 pb-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-8 h-8"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z"
                          />
                        </svg>
                      </div>
                      <p className="hover:text-gray-500"> Share Ticket</p>
                    </button>
                  </Link>
                )}
              </Tab>
              <Tab as={Fragment}>
                {({ selected }) => (
                  // <Link to="#" className="hover:text-gray-400">
                  <>
                    <button
                      className="p-3 ml-5 mb-3 text-black hover:border rounded "
                      onClick={handlePrint}
                      mediaPrint
                      media="print"
                    >
                      <div className="ml-14 pb-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-8 h-8"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                          />
                        </svg>
                      </div>
                      <p className="hover:text-gray-500">Download Ticket pdf</p>
                    </button>

                    <div className="hidden">
                      <NDCTicketInfo
                        ref={componentRef}
                        ticketData={response?.Response}
                        passengerInfo={booking?.passengers}
                        companyInfo={booking?.company}
                      />
                      {/* <TicketPrint
                        ref={componentRef}
                        // passengerList={travelers}
                        passengerList={passengerList}
                        airReservation={airReservation}
                        ticketNumbers={ticketNumbers}
                        flightDestination={destination}
                      /> */}
                    </div>
                  </>
                )}
              </Tab>
              <Tab as={Fragment}>
                {({ selected }) => (
                  <>
                    {/* <ReactToPrint
                      trigger={() => (
                        <button className="p-3 ml-5 mb-3 text-black hover:border rounded ">
                          <div className="ml-5 pb-3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-8 h-8"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z"
                              />
                            </svg>
                          </div>
                          <p className="hover:text-gray-500">Print Ticket</p>
                        </button>
                      )}
                      // content={() => componentRef.current}
                      content={reactToPrintContent}
                    /> */}
                    <div className="hidden">
                      {/* <DirectPrint
                        ref={componentRef}
                        passengerList={passengerList}
                        airReservation={airReservation}
                        ticketNumbers={ticketNumbers}
                        flightDestination={destination}
                        passengerInfo={booking?.passengers}
                        companyInfo={booking?.company}
                        travelers={travelers}
                        reservation={reservation}
                      /> */}
                      {/* <TicketPrint
                        ref={componentRef}
                        // passengerList={travelers}
                        passengerList={passengerList}
                        airReservation={airReservation}
                        ticketNumbers={ticketNumbers}
                        flightDestination={destination}
                      /> */}
                    </div>
                  </>
                )}
              </Tab>
            </Tab.List>
          </Tab.Group>
        </div>
        <div>
          <ButtonPrimary href="/">Explore more flights</ButtonPrimary>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
      <main className="container mt-11 mb-24 lg:mb-32 ">
        <div className="max-w-4xl mx-auto">{renderContent()}</div>
      </main>
    </div>
  );
};

export default DonePage;
