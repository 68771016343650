import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { Modal } from "react-bootstrap";
import { MenuItem, TextField } from "@mui/material";
import convertNumbThousand from "../../../utils/convertNumbThousand";
import { Formik } from "formik";
import { WALLET_MINIMUM_AMOUNT } from "../../../common/constants";
import { toast } from "react-toastify";
import { USDSettlement } from "../../../redux2/billing/billingSlice";

const USDCreditSettlement = ({ billing, id }) => {
  const [openModal, setOpenModal] = useState(false);

  const [exchangeRate, setExchangeRate] = useState(0);

  const dispatch = useDispatch();

  const handleClick = () => {
    setOpenModal(!openModal);
  };

  function handleCreditDeposit(values) {
    setOpenModal(false);

    if (!exchangeRate || exchangeRate <= 0) {
      toast.error("Please Enter Exchange Rate");
      return;
    }

    if (
      values.bank === "wallet" &&
      billing?.company?.totalCreditLimit - billing?.company?.creditLimit >
        billing?.company?.wallet - WALLET_MINIMUM_AMOUNT
    ) {
      toast.error("Insufficient Balance");
      return;
    }

    const data = {
      ...values,
      exchangeRate: +exchangeRate,
      id,
    };

    dispatch(USDSettlement(data));
    setExchangeRate(0);
  }

  return (
    <div>
      <div className="cursor-pointer" onClick={handleClick}>
        USD Credit Settlement
      </div>
      <Modal
        show={openModal}
        className="modal fade"
        onHide={() => {
          setOpenModal(false);
          setExchangeRate(0);
        }}
        size="md"
      >
        <div className="mb-3" role="document">
          <div className="">
            <div className="modal-header">
              <h4 className="modal-title fs-20">Manual Credit Payment</h4>

              <button
                type="button"
                className=" font-bold text-3xl text-red-500 rounded-full shadow-
              xl hover:outline-none border-none hover:ring-2 px-2"
                onClick={() => setOpenModal(false)}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <input
                type="number"
                className="bg-gray-50 border border-main text-black text-sm rounded-lg focus:ring-blue-500 focus:border-red-500 block w-full p-2.5 mb-3"
                placeholder="Enter Today's USD Exchange Rate"
                min={1}
                onChange={(e) => setExchangeRate(e.target.value)}
                onWheel={(e) => e.target.blur()}
                required
              />
              <h4 className="fs-18 mb-4">
                Outstanding USD credit payment amount:{" "}
                <p className="">
                  In USD{" "}
                  <b>
                    {convertNumbThousand(
                      billing?.company?.USDTotalCreditLimit -
                        billing?.company?.USDCreditLimit
                    )}
                  </b>
                </p>
                <p className="">
                  In Birr{" "}
                  <b>
                    {exchangeRate > 0 &&
                      convertNumbThousand(
                        (billing?.company?.USDTotalCreditLimit -
                          billing?.company?.USDCreditLimit) *
                          exchangeRate
                      )}
                  </b>
                </p>{" "}
              </h4>
              <i className="flat-icon-cancel-12 close text-black"></i>
              <div className="add-contact-box">
                <Formik
                  initialValues={{
                    transaction: "",
                    reason: "",
                    bank: "",
                  }}
                  onSubmit={handleCreditDeposit}
                  // validationSchema={EmployeeSchema}
                >
                  {({ values, handleChange, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit} className="">
                      <div className="flex flex-col justify-center">
                        <div className="row">
                          <div className=" mb-3">
                            <TextField
                              fullWidth
                              id="bank"
                              name="bank"
                              size="small"
                              value={values.bank}
                              label="Bank"
                              onChange={handleChange}
                              select
                              required
                              // error={touched.bank && Boolean(errors.bank)}
                              // helperText={touched.bank && errors.bank}
                            >
                              <MenuItem value="cbe">CBE</MenuItem>
                              <MenuItem value="awash">Awash Bank</MenuItem>
                              <MenuItem value="abyssinia">
                                Abyssinia Bank
                              </MenuItem>
                              <MenuItem value="amhara">Amhara Bank</MenuItem>
                              <MenuItem value="wallet">Wallet</MenuItem>
                            </TextField>
                          </div>
                          <div className="mb-3">
                            <TextField
                              fullWidth
                              id="transaction"
                              type="text"
                              size="small"
                              variant="outlined"
                              name="transaction"
                              placeholder="Transaction ID/ Payment Reference"
                              label="Transaction ID/ Payment Reference"
                              // disabled={checker?.data?.user?.email ?? false}
                              value={values.transaction}
                              onChange={handleChange}
                              required={values.bank !== "wallet"}
                              // error={touched.email && Boolean(errors.email)}
                              // helperText={touched.email && errors.email}
                            />
                          </div>

                          <div className="mb-8">
                            <TextField
                              fullWidth
                              id="reason"
                              type="text"
                              size="small"
                              variant="outlined"
                              name="reason"
                              placeholder="Reason"
                              label="Reason"
                              // disabled={checker?.data?.user?.email ?? false}
                              value={values.reason}
                              onChange={handleChange}
                              required
                              // error={touched.email && Boolean(errors.email)}
                              // helperText={touched.email && errors.email}
                            />
                          </div>
                        </div>
                        <LoadingButton
                          className="mt-8"
                          variant="contained"
                          type="submit"

                          // loading={add?.loading}
                        >
                          Credit Paid
                        </LoadingButton>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default USDCreditSettlement;
