import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import exchangeApi from "./apiExchange";

// Get user from localStorage
const token = JSON.parse(localStorage.getItem("token"));

const initialState = {
  isError: false,
  isLoading: false,
  errorMessage: "",
  data: null,
  offer: null,
  token: token ? token : null,
};

// auth
export const auth = createAsyncThunk(
  "exchange/auth",
  async (user, thunkAPI) => {
    try {
      const res = await exchangeApi.auth();

      return res;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// eligibility
export const eligibility = createAsyncThunk(
  "exchange/eligibility",
  async (pnr, thunkApi) => {
    try {
      const token = thunkApi.getState().exchange.token;
      return await exchangeApi.eligibility(token, pnr);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const initiateExchange = createAsyncThunk(
  "exchange/initiateExchange",
  async (pnr, thunkApi) => {
    try {
      const token = thunkApi.getState().exchange.token;
      return await exchangeApi.initiateExchange(token, pnr);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const exchangeSearch = createAsyncThunk(
  "exchange/exchangeSearch",
  async (request, thunkApi) => {
    try {
      const token = thunkApi.getState().exchange.token;
      return await exchangeApi.exchangeSearch(token, request);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const modifyOffer = createAsyncThunk(
  "exchange/modifyOffer",
  async (request, thunkApi) => {
    try {
      const token = thunkApi.getState().exchange.token;
      return await exchangeApi.modifyOffer(token, request);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const logout = createAsyncThunk("exchange/logout", async () => {
  await exchangeApi.logout();
});

export const exchangeSlice = createSlice({
  name: "exchange",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.errorMessage = "";
      state.data = null;
      state.offer = null;
    },
  },
  extraReducers: {
    [auth.pending]: (state) => {
      state.isLoading = true;
    },
    [auth.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.token = action.payload.access_token;
    },
    [auth.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
      state.token = null;
    },
    [eligibility.pending]: (state) => {
      state.isLoading = true;
    },
    [eligibility.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.data = action.payload.TicketChangeEligibilityListResponse;
    },
    [eligibility.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
      state.data = null;
    },
    [initiateExchange.pending]: (state) => {
      state.isLoading = true;
    },
    [initiateExchange.fulfilled]: (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
      state.data = action.payload.ReservationResponse;
    },
    [initiateExchange.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
      state.data = null;
    },
    [exchangeSearch.pending]: (state) => {
      state.isLoading = true;
    },
    [exchangeSearch.fulfilled]: (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
      state.offer = action.payload.data;
    },
    [exchangeSearch.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
      state.offer = null;
    },
    [modifyOffer.pending]: (state) => {
      state.isLoading = true;
    },
    [modifyOffer.fulfilled]: (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
      state.data = action.payload;
    },
    [modifyOffer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
      state.data = null;
    },

    [logout.fulfilled]: (state) => {
      state.user = null;
    },
  },
});

export const { reset } = exchangeSlice.actions;
export default exchangeSlice.reducer;
