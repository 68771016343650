export const NDCRetrieveReq = (data) => ({
  Query: {
    Filters: {
      OrderID: {
        value: data?.PNR || data.value,
        Owner: data?.carrier || data.Owner,
        Channel: "NDC",
      },
    },
  },
});

export const NDCVoidReq = (data) => ({
  Query: {
    OrderID: [
      {
        value: data?.PNR || data.value,
        Owner: data?.carrier || data.Owner,
        Channel: "NDC",
      },
    ],
  },
});

export const NDCRepriceReq = (data) => ({
  orderId: data?.PNR || data.value,
  ownerCode: data?.carrier || data.Owner,
  channel: "NDC",
});

export const NDCAcceptOrderReq = (data, booking) => ({
  ownerCode: booking?.carrier,
  orderId: booking?.PNR,
  channel: "NDC",
  acceptRepricedOrder: {
    dataMap: data?.dataMap,
    paymentFunctions: [
      {
        paymentProcessingDetails: {
          typeCode: "CA",
          amount: {
            ...data?.repriceResult?.totalPrice?.totalAmount,
            nanos: 0,
          },
        },
      },
    ],
    augmentations: data?.augmentations,
  },
});
