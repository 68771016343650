// features/modal/modalSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const modalSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    openModal: (state, action) => {
      const { modalType, data } = action.payload;
      state[modalType] = {
        isOpen: true,
        data,
      };
    },
    closeModal: (state, action) => {
      const modalType = action.payload;
      if (state[modalType]) {
        state[modalType].isOpen = false;
        state[modalType].data = null;
      }
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
