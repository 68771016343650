const SelectPassenger = ({
  passengers,
  getTicketByRef,
  selectedPassenger,
  setSelectedPassenger,
}) => {
  return (
    <div className="max-w-5xl w-full mx-auto">
      <form action="">
        {/* Select All Button */}
        <div className="flex items-center justify-between mb-2 rounded-md">
          <h5 className="text-base font-medium text-center text-gray-800">
            Select Ticket to Exchange
          </h5>
          <div className="flex items-center">
            <input
              type="checkbox"
              id="select-all"
              checked={selectedPassenger?.length === passengers?.length}
              className="form-checkbox h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
              onChange={(e) => {
                const isChecked = e.target.checked;

                passengers?.forEach((passenger, index) => {
                  const passengerCheckboxId = `exchange-passenger-${index}`;
                  const passengerCheckbox =
                    document.getElementById(passengerCheckboxId);

                  if (passengerCheckbox) {
                    passengerCheckbox.checked = isChecked;
                  } else {
                    console.warn(
                      `Element with id "${passengerCheckboxId}" not found.`
                    );
                  }
                });

                setSelectedPassenger(isChecked ? passengers : []);
              }}
            />
            <label
              htmlFor="select-all"
              className="ml-3 block font-medium text-gray-700 cursor-pointer my-2 text-base"
            >
              Select All
            </label>
          </div>
        </div>
        {passengers?.map((passenger, index) => (
          <div
            className="flex items-center justify-between mb-3 p-2 rounded-md bg-gray-100 hover:bg-gray-200 transition duration-150 ease-in-out"
            key={index}
          >
            <div className="flex items-center">
              <input
                type="checkbox"
                name="exchange-passenger"
                id={`exchange-passenger-${index}`}
                className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                value={passenger.id}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedPassenger([...selectedPassenger, passenger]);
                  } else {
                    setSelectedPassenger(
                      selectedPassenger.filter(
                        (data) => data.id !== passenger.id
                      )
                    );
                  }
                }}
              />
              <label
                htmlFor={`exchange-passenger-${index}`}
                className="ml-3 block font-medium text-gray-700 cursor-pointer text-md mt-2"
              >
                {passenger?.PersonName?.Given} {passenger.lastName}(
                {passenger?.passengerTypeCode})
              </label>
            </div>
            <div className="text-gray-500">{getTicketByRef(passenger?.id)}</div>
          </div>
        ))}
      </form>
    </div>
  );
};

export default SelectPassenger;
