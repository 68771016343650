import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { auth, reset } from "../../redux2/exchange/exchangeSlice";
import { toast } from "react-toastify";
import { eligibility } from "../../services/exchange";
import Loading from "../Loading";

const ExchangeModal = ({ show, onHide, booking }) => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleClick = async (e) => {
    dispatch(reset());
    if (!token) {
      toast.error("Authentication failed. Please login again.");
      onHide();
      return;
    }

    // navigate("/exchange-eligibility", {
    //   state: {
    //     voidPassenger,
    //     booking,
    //   },
    // });
  };

  const { isLoading, token } = useSelector((state) => state.exchange);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reset());
    dispatch(auth());
  }, [dispatch]);

  async function handleEligibility() {
    // dispatch(eligibility(booking?.flight?.providerPnr));
    if (!token) {
      toast.error("Authentication failed. Please login again.");
      onHide();
      return;
    }

    const res = await eligibility(
      token,
      setLoading,
      booking?.flight?.providerPnr
    );
    if (res?.Result?.Error) {
      toast.error(res?.Result?.Error?.[0]?.Message);
    }

    if (res?.TicketChangeEligibility) {
      navigate(
        `/exchange-eligibility?Locator=${booking?.flight?.providerPnr}`,
        {
          state: { booking, data: res },
        }
      );
    }
  }

  // useEffect(() => {
  //   if (!isLoading && !token) {
  //     toast.error("Authentication failed. Please login again.");
  //     onHide();
  //   }
  // }, [isLoading, token, onHide]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-right"
    >
      {isLoading || loading ? (
        <Loading search />
      ) : (
        <Modal.Body>
          <div className="">
            <h1 className="text-2xl font-semibold mb-6 text-center text-gray-800">
              Check if you are eligible to exchange your ticket.
            </h1>
            <label htmlFor="">Reservation PNR</label>
            <div className="flex flex-row justify-center items-center">
              <input
                type="text"
                value={booking?.flight?.providerPnr}
                placeholder="Enter ticket number"
                disabled
                className="w-full border-2 border-main-300 p-2 rounded-lg text-black"
              />
              <button
                className="bg-main text-white px-6 py-2 rounded-lg ml-2"
                onClick={handleEligibility}
              >
                <i className="fas fa-search"></i>
              </button>
            </div>
          </div>
        </Modal.Body>
      )}
      <Modal.Footer>
        <div className="flex justify-end mt-6 gap-3">
          <button
            type="button"
            className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition duration-150 ease-in-out mr-2"
            onClick={onHide}
          >
            Cancel
          </button>

          {/* <button
            type="submit"
            className="px-4 py-2 bg-main text-white rounded-md hover:bg-main-800 transition duration-150 ease-in-out"
            // onClick={handleClick}
            onClick={handleEligibility}
          >
            Next
          </button> */}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ExchangeModal;
