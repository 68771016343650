export const paymentMethods = [
  {
    name: "wallet",
    icon: "https://www.kindpng.com/picc/m/580-5809740_white-wallet-logo-png-wallet-icon-ios-transparent.png",
    title: "Wallet",
  },
  {
    name: "credit",
    icon: "https://media.istockphoto.com/vectors/safe-payment-logo-template-designs-vector-illustration-vector-id1215256045?k=20&m=1215256045&s=612x612&w=0&h=IQX0a8uPVKSTGfJyWtWv3eXAMk4LTuQSmMtNqv_IBZs=",
    title: "Credit",
  },
  {
    name: "webirr_cbe",
    icon: "https://www.ethiopiatrustfund.org/wp-content/uploads/2018/09/cbe-logo.png",
    title: "CBE",
  },
  {
    name: "webirr_awash",
    icon: "https://play-lh.googleusercontent.com/6W3_GN3BhqJffOg3XrjStJ7ICaQWdn9DD4lid4zPA0N7O0Jz0Ifq4ymCspxaO-QwvYw",
    title: "Awash",
  },
  // {
  //   name: "webirr_abyssinia",
  //   icon: "https://play-lh.googleusercontent.com/9UY26ys1vxxqYPX_zL4BCBjuaCBMoXIf9yQ7RhPEqAoQJVnczlgFpWUCRem1GXzt3A",
  //   title: "Abyssinia",
  // },
  {
    name: "amhara",
    icon: "https://pbs.twimg.com/profile_images/1542232022726447104/dQxN5Fap_400x400.jpg",
    title: "Amhara",
  },
  {
    name: "USDCredit",
    icon: "https://www.ledger.com/wp-content/uploads/2021/05/usdc-logo.png",
    title: "USD Credit",
  },
  {
    name: "kacha",
    icon: "",
    title: "Kacha Credit",
  },
];
