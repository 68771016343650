import { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";
import { Tab } from "@headlessui/react";
import classNames from "classnames";
import { FaPlaneDeparture, FaPlaneArrival } from "react-icons/fa";
import { formatNumber } from "../../../utils/convertToNumber";
import { getPriceEach } from "../helpers";
import { LuBaggageClaim } from "react-icons/lu";

const BaggageModal = ({
  segment,
  offerPrice,
  getTravelerType,
  passengerIndex,
  setPassengerIndex,
  getCarryOnBaggage,
  getCheckedBaggage,
}) => {
  const [openRule, setOpenRule] = useState(false);
  const [segmentIndex, setSegmentIndex] = useState(0);

  const handleShowFareRule = () => {
    setOpenRule(true);
  };

  const OD = offerPrice?.[0]?.RequestedDate?.Associations?.map((item) => {
    return item.ApplicableFlight.OriginDestinationReferences[0];
  });

  const baggageList =
    offerPrice[passengerIndex]?.RequestedDate?.Associations[segmentIndex]
      ?.ApplicableFlight?.FlightSegmentReference?.[0]?.BagDetailAssociation;

  const baggages = baggageList?.CheckedBagReferences?.map((item) => {
    return getCheckedBaggage(item);
  });

  const carryOn = baggageList?.CarryOnReferences?.map((item) => {
    return getCarryOnBaggage(item);
  });

  function getSegmentDetail(ref) {
    return segment.find((item) => item.OriginDestinationKey === ref);
  }

  function renderCarryOn(index, carry) {
    return (
      <div
        key={index}
        className="mb-3 flex w-full flex-col gap-1 rounded-md bg-sky-200 px-3 py-2 font-normal text-black"
      >
        <p className="font-medium text-red-500">Carry on</p>
        <ul className="mx-2 flex list-disc flex-col gap-">
          <li>
            <div className="">
              {carry?.PieceAllowance?.map((item, index) => (
                <Fragment key={index}>
                  <div>
                    Total Quantity:{" "}
                    <span className="font-semibold">{item?.TotalQuantity}</span>
                  </div>
                  {item?.PieceMeasurements?.map((measure, index) => (
                    <div>
                      Quantity :{" "}
                      <span className="font-semibold">{measure?.Quantity}</span>
                    </div>
                  ))}
                </Fragment>
              ))}
              {carry?.WeightAllowance?.MaximumWeight?.map((item, index) => (
                <Fragment key={index}>
                  <div>
                    Maximum Weight :{" "}
                    <span className="font-semibold">
                      {item?.Value} {item?.UOM}
                    </span>
                  </div>
                  {item?.PieceMeasurements?.map((measure, index) => (
                    <div>
                      Quantity :{" "}
                      <span className="font-semibold">{measure?.Quantity}</span>
                    </div>
                  ))}
                </Fragment>
              ))}
              {carry?.AllowanceDescription?.Descriptions &&
                carry?.AllowanceDescription?.Descriptions?.Description.map(
                  (desc, index) => (
                    <div key={index}>
                      <p>
                        Description:{" "}
                        <span className="font-semibold">
                          {desc?.Text.value}
                        </span>
                      </p>
                    </div>
                  )
                )}
            </div>
          </li>

          <li>
            {carry?.hasMin && <p>Min Amount: {formatNumber(carry.min)}</p>}
            {carry.max && <p>Max Amount: {formatNumber(carry.max)}</p>}
          </li>
        </ul>
      </div>
    );
  }

  function renderBaggage(index, baggage) {
    return (
      <div
        key={index}
        className="mb-3 flex w-full flex-col gap-1 rounded-md bg-sky-200 px-3 py-2 font-normal text-black"
      >
        <p className="font-medium text-red-500">Baggage</p>
        <ul className="mx-2 flex list-disc flex-col gap-">
          <li>
            <div className="">
              {baggage?.PieceAllowance?.map((item, index) => (
                <Fragment key={index}>
                  <div>
                    Total Quantity:{" "}
                    <span className="font-semibold">{item?.TotalQuantity}</span>
                  </div>
                  {item?.PieceMeasurements?.map((measure, index) => (
                    <div>
                      Quantity :{" "}
                      <span className="font-semibold">{measure?.Quantity}</span>
                    </div>
                  ))}
                  {item?.Descriptions &&
                    item?.Descriptions?.Description.map((desc, index) => (
                      <div key={index}>
                        <p>
                          Description:{" "}
                          <span className="font-semibold">
                            {desc?.Text.value}
                          </span>
                        </p>
                      </div>
                    ))}
                </Fragment>
              ))}
              {baggage?.WeightAllowance?.MaximumWeight?.map((item, index) => (
                <Fragment key={index}>
                  <div>
                    Maximum Weight :{" "}
                    <span className="font-semibold">
                      {item?.Value} {item?.UOM}
                    </span>
                  </div>
                  {item?.PieceMeasurements?.map((measure, index) => (
                    <div>
                      Quantity :{" "}
                      <span className="font-semibold">{measure?.Quantity}</span>
                    </div>
                  ))}
                </Fragment>
              ))}
              {baggage?.AllowanceDescription?.Descriptions &&
                baggage?.AllowanceDescription?.Descriptions?.Description.map(
                  (desc, index) => (
                    <div key={index}>
                      <p>
                        Description:{" "}
                        <span className="font-semibold">
                          {desc?.Text.value}
                        </span>
                      </p>
                    </div>
                  )
                )}
            </div>
          </li>

          <li>
            {baggage?.hasMin && <p>Min Amount: {formatNumber(baggage.min)}</p>}
            {baggage.max && <p>Max Amount: {formatNumber(baggage.max)}</p>}
          </li>
        </ul>
      </div>
    );
  }

  return (
    <div>
      {/* <button className="dropdown-item cursor-pointer text-black">
        <div className="flex items-center justify-center gap-1">
          <LuBaggageClaim className="h-4 w-4 text-red-500" />
          <span>Baggage</span>
        </div>
      </button> */}
      <button
        onClick={handleShowFareRule}
        className="rounded-lg px-6 py-2 text-black"
      >
        <div className="flex items-center justify-center gap-1">
          <LuBaggageClaim className="h-4 w-4 text-red-500" />
          <span>Baggage</span>
        </div>
      </button>
      <div className="">
        <Modal
          show={openRule}
          className="modal fade"
          onHide={() => setOpenRule(false)}
          size="lg"
          centered
        >
          <Modal.Header closeButton className="bg-main">
            <Modal.Title className="text-xl text-white">
              Baggage Allowance per Passenger
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="flex justify-between gap-2 text-xs md:text-sm">
              <Tab.Group as="div" className="flex w-full gap-3">
                <Tab.List as="div" className="w-1/3 py-1 mt-14">
                  {OD?.map((item, index) => (
                    <Tab
                      key={index}
                      className={({ selected }) =>
                        classNames(
                          "w-full rounded-lg p-3 text-xs md:text-base font-medium leading-5",
                          "focus:outline-none",
                          selected
                            ? "text-main  border-1 border-main "
                            : "text-black hover:bg-white/[0.6] hover:text-gray-900"
                        )
                      }
                      onClick={() => setSegmentIndex(index)}
                    >
                      <div className="flex gap-2 items-center">
                        <div className="flex items-center gap-1">
                          <FaPlaneDeparture className=" text-blue-500 w-5 h-5 hidden md:block" />
                          <span className="font-medium text-gray-900 ">
                            {/* {item?.Departure?.AirportCode?.value} */}
                            {getSegmentDetail(item)?.DepartureCode?.value}
                          </span>
                        </div>
                        {" - "}

                        <div className="flex items-center gap-1">
                          <FaPlaneArrival className=" text-green-500 w-5 h-5 hidden md:block" />
                          <span className="font-medium text-gray-900">
                            {/* {item?.Arrival?.AirportCode?.value} */}
                            {getSegmentDetail(item)?.ArrivalCode?.value}
                          </span>
                        </div>
                      </div>
                    </Tab>
                  ))}
                </Tab.List>

                <Tab.Panels as="div" className="flex flex-col w-full ">
                  <Tab.Group as="div" className="flex flex-col gap-3">
                    <Tab.List as="div" className="py-1 flex flex-row">
                      {getPriceEach(offerPrice)?.map((item, i) => (
                        <Tab
                          key={i}
                          className={({ selected }) =>
                            classNames(
                              "rounded-lg px-4 py-2 text-base font-medium leading-5",
                              "focus:outline-none flex text-white",
                              selected
                                ? "bg-main "
                                : " hover:bg-white/[0.6] hover:text-gray-900"
                            )
                          }
                          onClick={() => setPassengerIndex(i)}
                        >
                          <div className="flex gap-2 items-center">
                            <div className="flex items-center gap-1">
                              <span className="font-normal tracking-wider text-gray-900">
                                {getTravelerType(item.type)}
                              </span>
                            </div>
                          </div>
                        </Tab>
                      ))}
                    </Tab.List>
                    <Tab.Panels as="div" className="flex w-full">
                      {offerPrice?.map((_, index) => {
                        return (
                          <Tab.Panel
                            className="w-full min-h-[250px] "
                            key={index}
                          >
                            {carryOn?.map((carry, index) =>
                              renderCarryOn(index, carry)
                            )}
                            {baggages?.map((baggage, index) =>
                              renderBaggage(index, baggage)
                            )}
                          </Tab.Panel>
                        );
                      })}
                    </Tab.Panels>
                  </Tab.Group>
                </Tab.Panels>
              </Tab.Group>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button
              type="button"
              className="rounded-lg bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-600"
              onClick={() => setOpenRule(false)}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default BaggageModal;
