import moment from "moment";
import React from "react";
import {
  TRIP_TYPE_ONE_WAY,
  TRIP_TYPE_ROUND_TRIP,
} from "../../../common/constants";

const PickDate = ({
  searchData,
  queryParam,
  searchFlight,
  selectedDate,
  setSelectedDate,
  setCarrier,
  exchange = false,
}) => {
  const options = {
    weekday: "long",
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  const options2 = {
    weekday: "long",
    month: "short",
    day: "numeric",
  };

  const generateDateList = (numberOfDays) => {
    const dateList = [];
    const today = new Date(queryParam.startDate);
    today.setDate(today.getDate() - 1);

    for (let i = 0; i < numberOfDays; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);

      const formattedDate = date.toLocaleDateString("en-US", options);

      dateList.push(formattedDate);
    }

    return dateList;
  };

  const dateList = generateDateList(8);

  let currentSelectedDate;
  let flightEndDate = new Date(queryParam?.endDate).toLocaleDateString(
    "en-US",
    options
  );

  if (selectedDate) {
    let date = moment(new Date(selectedDate), "ddd, MMM D").toDate();
    currentSelectedDate = date.toLocaleDateString("en-US", options);
  } else {
    setSelectedDate(
      new Date(queryParam?.startDate).toLocaleDateString("en-US", options)
    );
    currentSelectedDate = new Date(queryParam?.startDate).toLocaleDateString(
      "en-US",
      options
    );
  }

  const handleDateChange = (date) => {
    const dateData = moment(new Date(date), "ddd, MMM D").format("YYYY-MM-DD");

    let search;
    if (exchange) {
      search = {
        ...searchData,
        SearchCriteriaFlight: searchData.SearchCriteriaFlight.map(
          (flight, index) => ({
            ...flight,
            departureDate: index === 0 ? dateData : flight.departureDate,
          })
        ),
      };
    } else {
      queryParam?.tripType === TRIP_TYPE_ONE_WAY
        ? (search = {
            ...searchData,
            segments: [
              {
                ...searchData.segments[0],
                PreferredTime: dateData,
              },
            ],
          })
        : (search = {
            ...searchData,
            segments: [
              {
                ...searchData.segments[0],
                PreferredTime: dateData,
              },
              {
                ...searchData.segments[1],
              },
            ],
          });
      setCarrier(null);
    }

    setSelectedDate(date);

    searchFlight(search);
  };
  return (
    <nav className="py-2 bg-white shadow my-5 w-full rounded hidden md:block">
      <ul className="flex space-x-2 text-center gap-3 xl:gap-0 justify-center items-center overflow-x-scroll lg:overflow-x-auto">
        {dateList.map((date) => (
          <li
            key={date}
            className={` px-8  py-2 rounded-lg text-center text-xs lg:text-lg flex-shrink  ${
              currentSelectedDate === date
                ? "text-main font-extrabold "
                : queryParam?.tripType === TRIP_TYPE_ROUND_TRIP &&
                  new Date(date) >= new Date(flightEndDate)
                ? "text-gray-400 cursor-not-allowed"
                : "cursor-pointer text-black xl:hover:bg-gray-400 xl:hover:text-gray-900 transition-colors "
            }
        `}
            onClick={() => {
              const isRoundTripAndValidDate =
                queryParam?.tripType === TRIP_TYPE_ROUND_TRIP &&
                new Date(date) < new Date(flightEndDate);
              const isOneWay = queryParam?.tripType === TRIP_TYPE_ONE_WAY;

              if (isRoundTripAndValidDate || isOneWay) {
                handleDateChange(date);
              }
            }}
          >
            {new Date(date).toLocaleDateString("en-US", options2)}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default PickDate;
