import { CheckBox } from "@mui/icons-material";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getRequest,
  resetRequest,
  updateRequestStatus,
} from "../../redux2/request/requestSlice";
import Loading from "../../components/Loading";
import moment from "moment";
import { toast } from "react-toastify";
import convertNumbThousand from "../../utils/convertNumbThousand";
import { WALLET_MINIMUM_AMOUNT } from "../../common/constants";

const RequestDetail = () => {
  const { request, isLoading, errorMessage, isError } = useSelector(
    (state) => state.request
  );

  const { id } = useParams();
  const form = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getRequest(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
    }

    dispatch(resetRequest());
  }, [isError, errorMessage, dispatch]);

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   console.log("here");
  // };

  const handleApprove = (e) => {
    e.preventDefault();

    const formData = new FormData(form.current);

    let data = {
      status: "approved",
      tax: +formData.get("tax") || 0,
      penalty: +formData.get("penalty") || 0,
      exchangeDifference: +formData.get("exchangeDifference") || 0,
      totalNewBasePrice: +formData.get("totalNewBasePrice") || 0,
      chequeNumber: formData.get("chequeNumber") || "",
      newTicketNumbers: formData.get("newTicketNumbers")?.split(",") || [],
      ticketPNR: formData.get("ticketPNR") || null,
      retrievalPNR: formData.get("retrievalPNR") || null,
      payment: formData.get("payment") || "wallet",
      passengersId,
    };

    if (request?.type === "Exchange") {
      let changePrice = data?.penalty + data?.tax + data?.exchangeDifference;

      if (request?.company) {
        if (
          data?.payment === "wallet" &&
          request?.company?.wallet < changePrice + WALLET_MINIMUM_AMOUNT
        ) {
          toast.error("Company wallet is not sufficient");
          return;
        } else if (
          data?.payment === "credit" &&
          request?.company?.creditLimit < changePrice
        ) {
          toast.error("Company credit is not sufficient");
          return;
        }
      }
    }

    if (
      request?.type === "Refund" &&
      (!formData.get("tax") || !formData.get("penalty"))
    ) {
      toast.error("Please fill all the fields");
      return;
    }

    if (
      request?.type === "Refund" &&
      request?.withdrawMethod === "cheque" &&
      !formData.get("chequeNumber")
    ) {
      toast.error("Please fill cheque number");
      return;
    }

    if (
      request?.type === "Exchange" &&
      (!formData.get("tax") ||
        !formData.get("penalty") ||
        !formData.get("exchangeDifference") ||
        !formData.get("newTicketNumbers"))
      // !formData.get("ticketPNR")
      // !formData.get("retrievalPNR"))
    ) {
      toast.error("Please fill all the fields");
      return;
    }

    if (
      request?.ticketNumbers?.length !==
        booking?.flight?.ticketNumber?.length &&
      request?.type !== "Exchange" &&
      !formData.get("totalNewBasePrice")
    ) {
      toast.error("Please add new ticket price");
      return;
    }

    dispatch(updateRequestStatus({ id, data }));
    navigate("/requests");
  };

  const handleProcessing = (e) => {
    e.preventDefault();

    dispatch(updateRequestStatus({ id, data: { status: "processing" } }));
  };

  if (isLoading) return <Loading />;

  let user = request?.user;
  let booking = request?.booking;
  let flight = booking?.flight;
  let company = request?.company;
  let corporate = request?.corporate;

  let passengersId = [];

  for (let i = 0; i < flight?.ticketNumber?.length; i++) {
    if (request?.ticketNumbers.includes(flight?.ticketNumber[i])) {
      passengersId.push(booking?.passengers[i]?._id);
    }
  }

  return (
    <>
      <div className="relative">
        <section className="pt-14 mx-8">
          <div className="absolute top-10 left-10">
            <Link to="/requests">
              <button className="btn bg-main hover:bg-main-800 text-white">
                Go Back
              </button>
            </Link>
          </div>
          <form
            method="post"
            id="contactForm"
            className="max-w-5xl flex flex-col gap-2 mx-auto font-medium text-md outline-none "
            ref={form}
            // onSubmit={handleSubmit}
          >
            <h1 className="font-sans uppercase text-center text-xl">
              Request Detail
            </h1>
            <br />
            <div className="flex justify-between gap-5">
              <div className="w-1/2">
                <h1 className="py-1">From Company Name</h1>
                <input
                  className="contact-input-field"
                  required
                  type="text"
                  id="name"
                  name="fromCompanyName"
                  disabled
                  placeholder="From Company Name"
                  value={company?.name || corporate?.name || ""}
                  // value={user?.corporate?.name || booking?.company?.name || ""}
                />
              </div>

              <div className="w-1/2">
                <h1 className="py-1">Agent Name</h1>
                <input
                  className="contact-input-field"
                  required
                  type="text"
                  id="name"
                  name="fromAgentName"
                  placeholder="From Agent Name"
                  disabled
                  value={user?.firstName + " " + user?.lastName}
                />
              </div>
            </div>

            <div className="flex justify-between gap-5">
              <div className="w-1/2">
                <h1 className="py-1">Flight Detail</h1>
                <input
                  className="contact-input-field"
                  required
                  type="text"
                  id="flightDetail"
                  name="flightDetail"
                  disabled
                  placeholder="Flight Detail"
                  value={flight?.origin + "-" + flight?.destination}
                  // value={user?.corporate?.name || booking?.company?.name || ""}
                />
              </div>

              <div className="w-1/2">
                <h1 className="py-1">Carrier</h1>
                <input
                  className="contact-input-field"
                  required
                  type="text"
                  id="carrier"
                  name="carrier"
                  disabled
                  placeholder="Carrier"
                  value={flight?.carrier}
                />
              </div>
            </div>

            <div className="flex justify-between gap-5">
              <div className="w-1/2">
                <h1 className="py-1">Trip Type</h1>
                <input
                  className="contact-input-field"
                  required
                  type="text"
                  id="tripType"
                  name="tripType"
                  disabled
                  placeholder="Trip Type"
                  value={flight?.isRoundTrip ? "Round Trip" : "One Way"}
                  // value={user?.corporate?.name || booking?.company?.name || ""}
                />
              </div>

              <div className="w-1/2">
                <h1 className="py-1">Request Type</h1>
                <input
                  className="contact-input-field"
                  required
                  type="text"
                  id="type"
                  name="type"
                  disabled
                  placeholder="Request Type"
                  value={request?.type}
                  // value={user?.corporate?.name || booking?.company?.name || ""}
                />
              </div>
            </div>

            <div className="flex justify-between gap-5">
              <div className="w-1/2">
                <h1 className="py-1">
                  System Fare
                  <span className="text-gray-500"> in birr</span>
                </h1>
                <input
                  className="contact-input-field"
                  required
                  type="text"
                  id="price"
                  name="price"
                  disabled
                  placeholder="Base Price"
                  value={convertNumbThousand(flight?.price)}
                  // value={user?.corporate?.name || booking?.company?.name || ""}
                />
              </div>

              <div className="w-1/2">
                <h1 className="py-1">
                  Service Charge
                  <span className="text-gray-500"> in birr</span>
                </h1>
                <input
                  className="contact-input-field"
                  required
                  type="text"
                  id="serviceCharge"
                  name="serviceCharge"
                  disabled
                  placeholder="Service Charge"
                  value={convertNumbThousand(booking?.serviceCharge)}
                  // value={user?.corporate?.name || booking?.company?.name || ""}
                />
              </div>
            </div>

            <div className="flex justify-between gap-5">
              <div className="w-1/2">
                <h1 className="py-1">Issued On</h1>
                <input
                  className="contact-input-field"
                  required
                  type="date"
                  id="issuedOn"
                  name="issuedOn"
                  disabled
                  placeholder="Issued On"
                  value={moment(request?.booking?.createdAt).format(
                    "YYYY-MM-DD"
                  )}
                />
              </div>

              <div className="w-1/2">
                <h1 className="py-1">Requested On</h1>
                <input
                  className="contact-input-field"
                  required
                  type="date"
                  id="requestedOn"
                  name="requestedOn"
                  disabled
                  placeholder="Requested On"
                  value={moment(request?.createdAt).format("YYYY-MM-DD")}
                />
              </div>
            </div>

            <div className="flex justify-between gap-5">
              <div className="w-1/2 pr-5">
                <h1 className="py-1">Request Status</h1>
                <input
                  className="contact-input-field"
                  required
                  type="string"
                  id="requestStatus"
                  name="requestStatus"
                  disabled
                  placeholder="Request Status"
                  value={request?.status}
                />
              </div>

              <div className="w-1/2 pr-5">
                <h1 className="py-1">Withdraw Method</h1>
                <input
                  className="contact-input-field"
                  required
                  type="string"
                  id="withdrawMethod"
                  name="withdrawMethod"
                  disabled
                  placeholder="Withdraw By"
                  value={request?.withdrawMethod}
                />
              </div>
            </div>

            {/* <select name="tickets" id="tickets" className="select-field">
            <option value="" hidden>
              Ticket Number
            </option>
            {booking?.flight?.ticketNumber?.map((ticket, index) => (
              <option value={booking?.flight?.ticketNumber} key={index}>
                {ticket}
              </option>
            ))}
          </select> */}

            <h1 className="py-1">Ticket Number</h1>
            {request?.ticketNumbers?.map((ticket, index) => (
              <div className="flex gap-2 px-2" key={index}>
                <CheckBox className="" name="ticketNumber" />
                <p className="text-black">{ticket}</p>
              </div>
            ))}

            {request?.type === "Exchange" && (
              <div className="flex justify-between gap-5">
                {moment(request?.departureDate).format("YYYY-MM-DD") !==
                  moment(flight?.departureTime).format("YYYY-MM-DD") && (
                  <div className="w-1/2">
                    <h1 className="py-1 text-main">New Departure Date</h1>
                    <input
                      className="contact-input-field"
                      required
                      type="date"
                      id="departureDate"
                      name="departureDate"
                      disabled
                      placeholder="Departure Date"
                      value={moment(request?.departureDate).format(
                        "YYYY-MM-DD"
                      )}
                    />
                  </div>
                )}
                {flight?.isRoundTrip &&
                  moment(request?.returnDate).format("YYYY-MM-DD") !==
                    moment(
                      booking?.flight?.routes?.[1]?.departureTime?.[0] ||
                        booking?.flight?.routes?.[1]?.departureTime
                    ).format("YYYY-MM-DD") && (
                    <div className="w-1/2">
                      <h1 className="py-1 text-main">New Return Date</h1>
                      <input
                        className="contact-input-field"
                        required
                        type="date"
                        id="returnDate"
                        name="returnDate"
                        disabled
                        placeholder="Return Date"
                        value={moment(request?.returnDate).format("YYYY-MM-DD")}
                      />
                    </div>
                  )}
              </div>
            )}

            <h1 className="py-1">Message</h1>
            <textarea
              className="contact-input-field"
              type="text"
              disabled
              id="message"
              name="message"
              placeholder="Message"
              value={request?.message}
            />

            {request?.status !== "approved" &&
              request?.ticketNumbers?.length !==
                booking?.flight?.ticketNumber?.length && (
                <div className="w-1/2 mt-3">
                  <h1 className="py-1">
                    Total New System Fare
                    <span className="text-gray-500"> in birr</span>
                  </h1>
                  <span>
                    New System fare + Penalty + Tax + Flight
                    Difference(Exchange)
                  </span>
                  <input
                    className="contact-input-field text-black"
                    required
                    type="number"
                    id="totalNewBasePrice"
                    name="totalNewBasePrice"
                    min={0}
                    placeholder="Total New System Fare"
                    onWheel={(e) => e.target.blur()}
                    // value={
                    //   request?.status === "approved"
                    //     ? booking?.flight?.price
                    //     : ""
                    // }
                    disabled={
                      request?.status === "approved" ||
                      request?.type === "Exchange"
                    }
                  />
                </div>
              )}

            {request?.type === "Refund" &&
              request?.withdrawMethod === "cheque" && (
                <div className="flex justify-between gap-5 mt-3">
                  <div className="w-1/2">
                    <h1 className="py-1">Cheque Number</h1>
                    <input
                      className="contact-input-field text-black"
                      required
                      type="text"
                      id="name"
                      name="chequeNumber"
                      onWheel={(e) => e.target.blur()}
                      // min={0}
                      placeholder="Cheque Number"
                      value={booking?.chequeNumber}
                      // disabled={booking?.penalty ? true : false}
                    />
                  </div>
                </div>
              )}

            {request?.type !== "Void" && (
              <>
                <div className="">
                  <div className="flex justify-between gap-5 mt-3">
                    <div className="w-1/2">
                      <h1 className="py-1">
                        Penalty
                        <span className="text-gray-500"> in birr</span>
                      </h1>
                      <input
                        className="contact-input-field text-black"
                        required
                        type="number"
                        id="name"
                        name="penalty"
                        onWheel={(e) => e.target.blur()}
                        // min={0}
                        placeholder="Penalty"
                        value={
                          request?.status === "approved"
                            ? booking?.penalty
                            : null
                        }
                        // disabled={booking?.penalty ? true : false}
                      />
                    </div>

                    <div className="w-1/2">
                      <h1 className="py-1">
                        Tax <span className="text-gray-500"> in birr</span>{" "}
                      </h1>
                      <input
                        className="contact-input-field text-black"
                        required
                        type="number"
                        id="tax"
                        name="tax"
                        min={0}
                        onWheel={(e) => e.target.blur()}
                        placeholder="Tax"
                        value={
                          request?.status === "approved" ? booking?.tax : null
                        }
                        // disabled={booking?.tax ? true : false}
                      />
                    </div>
                  </div>

                  {request?.type === "Exchange" && (
                    <div className="row py-3">
                      <div className="w-1/2 col-md-4">
                        <h1 className="py-1">Ticket PNR</h1>
                        <input
                          className="contact-input-field text-black"
                          required
                          type="text"
                          id="ticketPNR"
                          name="ticketPNR"
                          placeholder="Ticket PNR"
                          onWheel={(e) => e.target.blur()}
                          value={booking?.retrievalPNR}
                        />
                      </div>

                      <div className="w-1/2 col-md-4">
                        <h1 className="py-1">Retrieval PNR</h1>
                        <input
                          className="contact-input-field text-black"
                          required
                          type="text"
                          id="retrievalPNR"
                          name="retrievalPNR"
                          placeholder="Retrieval PNR"
                          onWheel={(e) => e.target.blur()}
                          value={booking?.retrievalPNR}
                        />
                      </div>

                      <div className="w-1/2 col-md-4">
                        <h1 className="py-1">Ticket Number</h1>
                        <input
                          className="contact-input-field text-black"
                          required
                          type="text"
                          id="newTicketNumbers"
                          name="newTicketNumbers"
                          placeholder="Ticket Number"
                          onWheel={(e) => e.target.blur()}
                          value={
                            request?.status === "approved"
                              ? booking?.flight?.ticketNumber
                              : null
                          }
                          disabled={
                            request?.status === "approved" ? true : false
                          }
                        />
                        <p className="text-xs text-grey-500">
                          if ticket is more than one use coma to add multiple
                          ticket
                        </p>
                      </div>

                      <div className="w-1/2 col-md-4">
                        <h1 className="py-1">
                          Flight Difference
                          <span className="text-gray-500"> in birr</span>
                        </h1>
                        <input
                          className="contact-input-field text-black"
                          required
                          type="number"
                          id="exchangeDifference"
                          name="exchangeDifference"
                          onWheel={(e) => e.target.blur()}
                          min={0}
                          placeholder="Flight Difference"
                          // value={booking?.exchangeDifference}
                          value={
                            request?.status === "approved"
                              ? booking?.exchangeDifference
                              : null
                          }
                          // disabled={booking?.exchangeDifference ? true : false}
                        />
                      </div>

                      <div className="w-1/2 col-md-4 mt-3">
                        <div className="my-1">
                          <h2 className="mb-1">Payment Method:</h2>
                          <select
                            name="payment"
                            id="payment"
                            className="select-field text-black"
                            required
                            value={request?.payment}
                          >
                            <option value="" hidden>
                              Payment Method
                            </option>
                            <option value="wallet" selected>
                              Wallet
                            </option>
                            <option value="credit">Credit</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}

            <div className="flex justify-end space-x-4 my-5">
              {request?.status !== "approved" && (
                <button
                  className="p-2 outline outline-green-500 rounded-md text-black bg-white hover:bg-gray-100 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110 hover:shadow-xl focus:outline-none"
                  onClick={handleApprove}
                >
                  Approve
                </button>
              )}
              {request?.status === "pending" && (
                <button
                  className="p-2 outline outline-sky-500 rounded-md text-black bg-white hover:bg-gray-100 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110 hover:shadow-xl focus:outline-none"
                  onClick={handleProcessing}
                >
                  Processing
                </button>
              )}
              {(request?.status === "pending" ||
                request?.status === "processing") && (
                <button className="p-2 outline outline-red-500 rounded-md text-black bg-white hover:bg-gray-100 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110 hover:shadow-xl focus:outline-none">
                  Reject
                </button>
              )}
            </div>
          </form>
        </section>
      </div>
    </>
  );
};

export default RequestDetail;
