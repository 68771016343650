import moment from "moment";

export const calculateTime = (departure, arrival) => {
  let time = moment(departure).diff(moment(arrival), "minutes");

  let hours = Math.floor(time / 60);
  let minutes = time % 60;

  return `${hours}h ${minutes}m`;
};

export const addDateAndTime = (dateStr, timeStr) => {
  const date = new Date(dateStr);

  const [hours, minutes] = timeStr.split(":");

  date.setHours(hours);
  date.setMinutes(minutes);

  const result = date.toISOString();
  return result;
};

export const mergeObjects = (A, B) => {
  const combined = [...A, ...B];

  const grouped = combined.reduce((acc, obj) => {
    const key = `${obj.type}-${obj.code}-${obj.message}`;
    if (!acc[key]) {
      acc[key] = { ...obj };
    } else {
      acc[key].min = Math.min(acc[key].min, obj.min);
      acc[key].max = Math.max(acc[key].max, obj.max);
    }
    return acc;
  }, {});

  const updateArray = (arr) =>
    arr.map((obj) => {
      const key = `${obj.type}-${obj.code}-${obj.message}`;
      if (grouped[key]) {
        return { ...obj, min: grouped[key].min, max: grouped[key].max };
      }
      return obj;
    });

  return {
    A: updateArray(A),
    B: updateArray(B),
  };
};
