export const groupOffersByReference = (offers) => {
  return offers?.reduce((acc, offer) => {
    const reference = offer?.PricedOffer?.Associations?.map((flights) =>
      flights.ApplicableFlight.FlightSegmentReference?.map((Ref) => Ref.ref)
    );
    if (!acc[reference]) {
      acc[reference] = [];
    }
    acc[reference].push(offer);
    return acc;
  }, {});
};

export const groupDataListByReference = (reference, dataSet) => {
  let references = reference.split(",");
  let result = [];
  for (let i = 0; i < references.length; i++) {
    result = result.concat(
      dataSet?.DataLists?.FlightSegmentList?.FlightSegment.reduce(
        (acc, data) => {
          if (data.SegmentKey === references[i]) {
            acc.push(data);
          }
          return acc;
        },
        []
      )
    );
  }
  return result;
};

export const getSegment = (reference, dataSet) => {
  return dataSet?.DataLists?.FlightSegmentList?.FlightSegment?.find(
    (item) => item.SegmentKey === reference
  );
};

export const getAugmentationPrice = (ref, Metadata) => {
  return Metadata?.[0]?.PriceMetadatas?.PriceMetadata?.find(
    (item) => item.MetadataKey === ref
  );
};

export const getMetadateResponse = (ref, Metadata) => {
  return Metadata[0].DescriptionMetadatas?.DescriptionMetadata[0]?.AugmentationPoint?.AugPoint?.find(
    (item) => item.Owner === ref
  );
};

export const getClassType = (ref, dataSet) => {
  return dataSet?.DataLists?.PriceClassList?.PriceClass?.find(
    (item) => item.ObjectKey === ref
  )?.Name;
};

export const getFareType = (refs, dataSet) => {
  if (!refs || refs.length < 1) return;
  return refs?.map((ref) =>
    dataSet?.DataLists?.FareList?.FareGroup.find((item) => {
      // if (item.ListKey === ref) {
      //   console.log(item);
      //   return {
      //     FareBasisCode: item.FareBasisCode,
      //     ListKey: item.ListKey,
      //     refs: item.refs,
      //   };
      // }
      return item.ListKey === ref;
    })
  );
};

export const getTravelerObjectKey = (refs, dataSet) => {
  if (Array.isArray(refs)) {
    return refs.map((ref) =>
      dataSet?.DataLists?.AnonymousTravelerList?.AnonymousTraveler?.find(
        (item) => item.ObjectKey === ref
      )
    );
  }
  return dataSet?.DataLists?.AnonymousTravelerList?.AnonymousTraveler?.find(
    (item) => item.ObjectKey === refs
  )?.PTC;
};

export const getOfferItemID = (offer) => {
  return offer.map((item) => {
    return {
      refs: item.RequestedDate.Associations[0].AssociatedTraveler
        .TravelerReferences,
      value: item.OfferItemID,
    };
  });
  // return offer.map((item) => {
  //   return item.RequestedDate.Associations?.map((item) => {
  //     return {
  //       refs: item.AssociatedTraveler.TravelerReferences,
  //       value: item.OfferItemID,
  //     };
  //   });
  // });
};

export function getPriceEach(price) {
  return price?.map((item) => {
    // return (
    //   item.RequestedDate.PriceDetail.TotalAmount.SimpleCurrencyPrice.value *
    //   item.RequestedDate.Associations[0].AssociatedTraveler.TravelerReferences
    //     .length
    // );
    return {
      price:
        item.RequestedDate.PriceDetail.TotalAmount.SimpleCurrencyPrice.value *
        item.RequestedDate.Associations[0].AssociatedTraveler.TravelerReferences
          .length,
      type: item.RequestedDate.Associations[0].AssociatedTraveler
        .TravelerReferences[0],
      currency:
        item.RequestedDate.PriceDetail.TotalAmount.SimpleCurrencyPrice.Code,
      count:
        item.RequestedDate.Associations[0].AssociatedTraveler.TravelerReferences
          .length,
    };
  });
}

export const getTravelerType = (dataSet, ref) => {
  console.log(dataSet);
  return dataSet?.AnonymousTraveler?.find((item) => item.ObjectKey === ref)?.PTC
    .value;
};

export const paymentReq = (data, basePrice) => {
  return {
    Payments: {
      Payment: [
        {
          Amount: {
            value: basePrice,
            Code: data.PricedFlightOffers.PricedFlightOffer[0].OfferPrice[0]
              .RequestedDate.PriceDetail.TotalAmount.SimpleCurrencyPrice.Code,
          },
          Method: {
            Cash: {
              CashInd: true,
            },
          },
        },
      ],
    },
  };
};
