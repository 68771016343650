import React, { Fragment, useState } from "react";
import moment from "moment";
import { getCarrierLogo } from "../../../utils/flightInfo";
import { addDateAndTime, calculateTime } from "../utils";
import Baggage from "../modals/Baggage";

const FlightSegment = ({
  segment,
  offerPrice,
  AnonymousTraveler,
  oneWayLength,
  getCarryOnBaggage,
  getCheckedBaggage,
}) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal((prev) => !prev);
  const [isOpen, setIsOpen] = useState(true);

  function getBaggage(ref) {
    return offerPrice?.map((item) =>
      item.RequestedDate.Associations?.map((assoc) =>
        assoc.ApplicableFlight.FlightSegmentReference?.find(
          (seg) => seg.ref === ref
        )
      )
    );
  }

  function getFlightRef(ref) {
    return offerPrice?.map((item) =>
      item.RequestedDate.Associations?.map((assoc) =>
        assoc.ApplicableFlight.FlightSegmentReference?.find(
          (seg) => seg.ref === ref
        )
      )
    );
  }

  const renderDetailTop = (flightInfo, index, showButton) => {
    let offer = offerPrice[0]?.RequestedDate.Associations;
    let baggage = offer?.map((item) => {
      return item.ApplicableFlight.FlightSegmentReference?.map((bag) => {
        return bag.BagDetailAssociation;
      });
    });
    let flightRef = offer?.map((item) => {
      return item.ApplicableFlight.FlightSegmentReference?.map((segment) => {
        return segment;
      });
    });
    // flat array
    flightRef = flightRef.flat();

    if (oneWayLength > index) {
      // baggage = baggage[0];
      baggage = baggage[0][0];
    } else {
      baggage = baggage[0][1];
    }

    // let baggage = getBaggage(flightInfo?.SegmentKey);

    let carryOn = getCarryOnBaggage(baggage?.CarryOnReferences?.[0]);
    let checked = getCheckedBaggage(baggage?.CheckedBagReferences?.[0]);

    return (
      <div>
        <div className="flex flex-col md:flex-row">
          <div className="mt-10 w-24 flex-shrink-0 md:w-20 md:pt-7">
            <img
              src={getCarrierLogo(flightInfo.MarketingCarrier.AirlineID.value)}
              className="w-15"
              alt=""
            />
          </div>
          <div className="my-3 flex md:my-0 lg:w-40">
            <div className="flex flex-shrink-0 flex-col items-center py-2">
              <span className="block h-6 w-6 rounded-full border border-neutral-400"></span>
              <span className="my-1 block flex-grow border-l border-dashed border-neutral-400"></span>
              <span className="block h-6 w-6 rounded-full border border-neutral-400"></span>
            </div>
            <div className="ml-4 space-y-20 text-base">
              <div className="flex flex-col space-y-1">
                <span className="font-extralight text-black">
                  {flightInfo?.Departure?.Time}
                </span>
                <span className="font-semibold text-black">
                  {flightInfo?.Departure?.AirportCode?.value}
                </span>
              </div>
              <div className="flex flex-col space-y-1">
                <span className="font-extralight text-black">
                  {flightInfo?.Arrival?.Time}
                </span>
                <span className="font-semibold text-black">
                  {flightInfo?.Arrival?.AirportCode?.value}
                </span>
              </div>
            </div>
          </div>
          <div className="mt-1 border-l border-neutral-200 md:mx-6 lg:mx-10"></div>
          <div className="flex flex-col justify-between gap-3">
            <div className="flex gap-3">
              <div className="space-y-1 text-base font-extralight text-black md:space-y-2">
                <div>AirLine:</div>
                <div>Trip time: </div>
                <div>Flight Class: </div>
                <div>Booking Class:</div>
                <div>Flight Number:</div>

                {/* <div>Booking Class</div> */}
                {/* <div>Carry on</div> */}
                {/* <div>Baggage Units</div> */}
              </div>
              <div className="space-y-1 text-base font-light text-black md:space-y-2">
                <div>{flightInfo?.MarketingCarrier?.Name}</div>
                <div>
                  {flightInfo?.FlightDetail?.FlightDuration?.Value.slice(2)}{" "}
                </div>
                <div>
                  {flightRef[index]?.ClassOfService?.MarketingName.value}
                </div>
                <div>{flightRef[index]?.ClassOfService?.Code.value}</div>
                <div>{flightInfo?.MarketingCarrier?.FlightNumber.value}</div>
              </div>
            </div>
            {/* <div className="flex justify-between gap-10">
              <div className="space-y-1 text-base font-extralight text-black">
                <div className="text-base font-semibold">Carry-On:</div>
                <div className="grid grid-cols-2 ">
                  <p className="">Piece:</p>
                  <div className="">
                    {carryOn?.PieceAllowance?.map((item, index) => (
                      <Fragment key={index}>
                        <div>
                          Total Quantity:{" "}
                          <span className="font-semibold">
                            {item?.TotalQuantity}
                          </span>
                        </div>
                      </Fragment>
                    ))}
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <p className="">Weight:</p>
                  <div className="">
                    {carryOn?.WeightAllowance?.MaximumWeight?.map(
                      (item, index) => (
                        <Fragment key={index}>
                          <div>
                            Maximum Weight:{" "}
                            <span className="font-semibold">{item?.Value}</span>
                          </div>
                          <div>
                            Unit:{" "}
                            <span className="font-semibold">{item?.UOM}</span>
                          </div>
                        </Fragment>
                      )
                    )}
                  </div>
                </div>
                <div className="text-base font-semibold mt-3">Checked Bag:</div>
                <div className="grid grid-cols-2">
                  <p className="">Piece:</p>
                  <div className="">
                    {checked?.PieceAllowance?.map((bag, index) => {
                      return (
                        <Fragment key={index}>
                          <div>
                            Total Quantity:{" "}
                            <span className="font-semibold">
                              {bag?.TotalQuantity}
                            </span>
                          </div>
                        </Fragment>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="space-y-1 text-base font-light text-black md:space-y-2">
                <div></div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  };

  const renderDetail = () => {
    if (!isOpen) return null;
    return (
      <div className="p-4 md:p-8 border border-gray-900 shadow-lg mx-6">
        {/* {cabinType[selectedCabin]?.map((items, index) => ( */}
        {segment.map((flightInfo, index) => {
          const showButton = index === segment?.length - 1;
          return (
            <div key={index}>
              {renderDetailTop(flightInfo, index, showButton)}
              {index === segment?.length - 1 ? null : (
                <div className="my-7 space-y-5 md:my-10">
                  <div className="border-t border-neutral-300" />
                  <div className="flex justify-between text-base font-light text-black">
                    {oneWayLength - 1 !== index ? (
                      "Transit time: Transit time: " +
                      calculateTime(
                        addDateAndTime(
                          segment?.[index + 1]?.Departure?.Date,
                          segment?.[index + 1]?.Departure?.Time
                        ),
                        addDateAndTime(
                          segment?.[index]?.Arrival?.Date,
                          segment?.[index]?.Arrival?.Time
                        )
                      )
                    ) : (
                      <p className="text-sm font-light text-red-500">
                        Return Flight
                      </p>
                    )}
                  </div>
                  <div className="border-t border-neutral-300" />
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <div className="col-md-12">
        <div className="card shadow-lg">
          <div className="row">
            <div className="card-header">
              <div className="col-md-6">
                <h3 className="card-title">Flight Detail</h3>
              </div>
              {/* <div className="col-md-6">
                <div className="float-right">
                  <h3 className="card-subtitle">
                    {flightInfo?.Group === "0"
                      ? "Depart Flight"
                      : "Return Flight"}
                  </h3>
                </div>
              </div> */}
            </div>
          </div>
          <div className="card-body" data-nc-id="FlightCard">
            <div className={`relative sm:pr-20`} data-nc-id="FlightCard">
              <div className="row">
                <div className="col-md-4">
                  <div className="flex items-center space-x-2">
                    <div className="text-xl text-black">
                      <h1>
                        {moment(segment[0]?.Departure?.Date).format("MMMM Do")}
                      </h1>
                      <h1>{segment[0]?.Departure?.Time}</h1>
                      <h1 className="py-2 text-lg font-extralight">
                        {segment[0]?.Departure?.AirportCode?.value}
                      </h1>
                    </div>
                  </div>
                </div>
                {/* {"->"} */}
                <div className="col-md-1">
                  <span className="flex w-12 justify-center">
                    <i className="las la-long-arrow-alt-right text-2xl"></i>
                  </span>
                </div>
                <div className="col-md-4">
                  <div className="flex items-center space-x-2">
                    <div className="text-xl text-black">
                      <h1>
                        {moment(segment.at(-1)?.Arrival?.Date).format(
                          "MMMM Do"
                        )}
                      </h1>
                      <h1>{segment.at(-1)?.Arrival?.Time}</h1>
                      <h1 className="py-2 text-lg font-extralight">
                        {segment.at(-1)?.Arrival?.AirportCode?.value}{" "}
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="flex items-center space-x-2 px-4 py-3">
                    <div className="text-lg text-black">
                      {/* <h1 className="mt-0.5 text-sm font-light text-black">
                        {calculateTime(
                          addDateAndTime(
                            segment.at(-1)?.Arrival?.Date,
                            segment.at(-1)?.Arrival?.Time
                          ),
                          addDateAndTime(
                            segment[0]?.Departure?.Date,
                            segment[0]?.Departure?.Time
                          )
                        )}
                      </h1> */}
                      {/* <h1>
                        {segment.length > 1
                          ? `${segment.length - 1} Stops`
                          : "Direct"}
                      </h1> */}
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-3 space-y-6">
                  <div className="flex justify-between space-x-2 py-2">
                    <div className="mt-0.5 text-sm font-light text-black">
                      <h1 className="pb-2">Flight Number</h1>
                      <h1 className="pb-2">Flight Equipment</h1>
                      <h1 className="pb-2">Flight Class</h1>
                      <h1 className="pb-2">Booking Class</h1>
                    </div>
                    <div className="">
                      <h1 className="pb-2">
                        {segment[0]?.MarketingCarrier?.FlightNumber.value}
                      </h1>
                      <h1 className="pb-2">
                        {segment[0]?.Equipment.AircraftCode.value}
                      </h1>
                      <h1 className="pb-2">
                        {isRoundTrip
                          ? flightDetails?.BookingInfo[index]?._attributes
                              ?.CabinClass
                          : flightDetails?.BookingInfo?._attributes?.CabinClass}
                      </h1>
                      <h1 className="pb-2">{flightInfo?.ClassOfService}</h1>
                    </div>
                  </div>
                </div> */}
                <div className="col-md-1">
                  <div className="flex items-center space-x-2 py-2">
                    <div className="w-24 flex-shrink-0 py-2 md:ml-10 lg:w-32">
                      <img
                        src={getCarrierLogo(
                          segment[0].MarketingCarrier.AirlineID.value
                        )}
                        className="w-16"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-lg font-medium text-black">
              <span
                className={`absolute bottom-0 right-5 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-neutral-50 sm:bottom-auto sm:-translate-y-1/2 dark:bg-neutral-800 ${
                  isOpen ? "-rotate-180 transform" : ""
                }`}
                onClick={() => setIsOpen(!isOpen)}
              >
                <i className="las la-angle-down text-xl"></i>
              </span>
            </div>
          </div>
          {/* <div className="col-md-3">
            <button
              onClick={openModal}
              className="text-main px-4 py-2 text-base font-bold"
            >
              Show Fare Rule
            </button>
          </div> */}
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 mb-10 mt-1">{renderDetail()}</div>
      </div>
      {/* <Baggage
        segment={segment}
        baggage={offerPrice}
        AnonymousTraveler={AnonymousTraveler}
      /> */}
      {/* <Modal
        show={showModal}
        onHide={openModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        cancelButton={true}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Fare Rule</h5>
            <button
              type="button"
              className="btn-close"
              onClick={openModal}
              data-dismiss="modal"
            >
              <i>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M15.854 1.646a.5.5 0 0 1 0 .708L1.707 15.854a.5.5 0 0 1-.708-.708L15.146 1.646a.5.5 0 0 1 .708 0z"
                  />
                  <path
                    fillRule="evenodd"
                    d="M1.646 1.646a.5.5 0 0 0 0 .708L15.793 15.854a.5.5 0 0 0 .708-.708L2.354 1.646a.5.5 0 0 0-.708 0z"
                  />
                </svg>
              </i>
            </button>
          </div>
          <div className="modal-body">
            {fareRule?.map((item, index) => {
              return (
                <div key={index}>
                  <p className="">
                    {item._attributes.Category}, - {item._text}
                  </p>
                  <br />
                </div>
              );
            })}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn"
              data-bs-dismiss="modal"
              onClick={openModal}
            >
              Close
            </button>
          </div>
        </div>
      </Modal> */}
    </>
  );
};

export default FlightSegment;
