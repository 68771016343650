import React from "react";
import ReactDOM from "react-dom/client";

import "./styles/output.css";
import "./styles/index.scss";
import "./styles/index.css";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "rc-slider/assets/index.css";
import "@y0c/react-datepicker/assets/styles/calendar.scss";

import { BrowserRouter } from "react-router-dom";
import App from "./App";
import ScrollToTop from "./routing/ScrollToTop";

import { Provider } from "react-redux";
import { store } from "./redux2/store";
import KachaCreditOTP from "./components/FlightBookingSteps/KachaCreditOTP";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop>
          <KachaCreditOTP />
          <App />
        </ScrollToTop>
      </BrowserRouter>
    </Provider>
  </>
);
