import { useState } from "react";
import moment from "moment";
import { FaPlaneArrival, FaPlaneDeparture } from "react-icons/fa";
import { getCarrierInfo, getCarrierLogo } from "../../utils/flightInfo";
import convertNumbThousand from "../../utils/convertNumbThousand";

const ExchangeFlights = ({
  className = "",
  segment,
  flight,
  selectedFlight,
  setSelectedFlight,
  onSelect,
  exchangeSc,
  Traveler,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [flightIndex, setFlightIndex] = useState(0);

  const oneWayLength = flight?.ProductOptions?.length > 1 ? false : true;

  let id =
    flight?.ProductOptions?.[flightIndex]?.Product?.[0]?.FlightSegment?.[0]
      ?.Flight?.FlightRef;
  // let product = flight?.ProductOptions?.[flightIndex]?.Product?.[0];
  let price = flight?.ModifyPrice;

  const getAirSegment = (id) => {
    return segment.find((flight) => flight.id === id);
  };

  function getTotalPrice() {
    const exchangeSC = (exchangeSc || 0) * Traveler?.length;
    const result = price?.TotalPrice + exchangeSC;

    return convertNumbThousand(result);
  }

  const renderDetailTop = (segment, stat, btnVisible, index, passenger) => {
    return (
      <div>
        <div className="flex flex-col md:flex-row items-center gap-10">
          <div className="w-24 md:w-20 lg:w-24 flex-shrink-0 md:pt-7">
            <img
              src={getCarrierLogo(segment?.carrier)}
              className="w-15"
              alt=""
            />
          </div>
          <div className="flex my-5 md:my-0 lg:w-56">
            <div className="flex-shrink-0 flex flex-col items-center py-2">
              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
              <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
            </div>
            <div className="ml-4 space-y-10 text-sm">
              <div className="flex flex-col space-y-1">
                <span className=" text-neutral-500 dark:text-neutral-400">
                  <>{segment?.Departure?.time.slice(0, -3)}</>
                </span>
                <span className=" font-semibold text-black">
                  {segment?.Departure?.location}
                </span>
              </div>
              <div className="flex flex-col space-y-1">
                <span className=" text-neutral-500 dark:text-neutral-400">
                  {segment?.Arrival?.time.slice(0, -3)}
                </span>
                <span className=" font-semibold text-black">
                  {segment?.Arrival?.location}
                </span>
              </div>
            </div>
          </div>
          <div className="border-l border-neutral-200 md:mx-6 lg:ml-10"></div>

          <ul className="text-sm text-neutral-500 space-y-1 mt-2 md:space-y-2">
            <li>
              Flight time: <span>{segment?.duration.slice(2)}</span>
            </li>
            <li>
              Flight Class:{" "}
              {passenger
                ?.map((item) => item?.FlightProduct?.[0]?.cabin)
                .join(", ")}
            </li>
            <li>Flight Number: {segment.number}</li>
            <li>Equipment: {segment.equipment}</li>
            <li>
              Booking Class:{" "}
              {passenger
                ?.map((item) => item?.FlightProduct?.[0]?.classOfService)
                .join(", ")}
            </li>
          </ul>

          {/* {stat ? (
            btnVisible && (
              <>
                <div className="mx-auto "></div>
                <div className="px-6 py-2 mt-auto flex flex-col">
                  <p className="text-xs text-red-600 p-2"></p>
                  <ButtonPrimary
                    onClick={() => {
                      // addSelectedFlight(flight);
                      // selectBtnLink(flight);
                      onSelect(flight);
                    }}
                  >
                    Select
                  </ButtonPrimary>
                </div>
              </>
            )
          ) : (
            <>
              <div className="mx-auto"></div>
              <div className="px-6 py-2 mt-auto flex flex-col">
                <p className="text-xs text-red-600 p-2"></p>
                <ButtonPrimary
                  onClick={() => {
                    onSelect(flight);
                    // addSelectedFlight(flight);
                    // setFlightIndex(1);
                  }}
                >
                  Select
                </ButtonPrimary>
              </div>
            </>
          )} */}
        </div>
      </div>
    );
  };

  const renderDetail = () => {
    if (!isOpen) return null;
    return (
      <div className="p-4 md:p-8 border border-neutral-200 dark:border-neutral-700 rounded-2xl ">
        {flight?.ProductOptions.map((segment, i) => {
          return segment?.Product?.[0]?.FlightSegment.map((item, index) => {
            const seg = getAirSegment(item?.Flight.FlightRef);
            const passenger = segment?.Product?.[0]?.PassengerFlight;

            return (
              <div key={index}>
                {renderDetailTop(
                  seg,
                  i !== flight.ProductOptions.length - 1,
                  index === segment.Product[0].FlightSegment.length - 1,
                  index,
                  passenger
                )}
                {index ===
                segment.Product[0].FlightSegment.length - 1 ? null : (
                  <div className="my-7 md:my-10 space-y-5 md:pl-24">
                    <div className="border-t border-neutral-200" />
                    <div className="text-neutral-700 text-sm md:text-base">
                      Transit time:{" "}
                      {flight.ProductOptions[
                        i
                      ]?.Product[0]?.totalDuration.slice(2)}
                    </div>
                    <div className="border-t border-neutral-200" />
                  </div>
                )}
              </div>
            );
          });
        })}
      </div>
    );
  };

  const renderOneWay = (seg, index) => {
    return (
      <div
        // key={index}
        className={`flex items-center justify-between ${
          oneWayLength - 1 === index
            ? "border-b border-gray-300 py-2 last:border-b-0"
            : "border-none"
        } `}
      >
        <div className="flex flex-col items-start">
          <div className="flex items-center">
            <FaPlaneDeparture className="mr-2 text-blue-500 w-4 h-4" />
            <span className="text-base font-medium text-gray-900">
              {seg?.Departure?.location}
            </span>
          </div>
          <span className="text-xs text-gray-600">
            {moment(seg?.Departure.date).format("ddd, MMM D")} at{" "}
            {seg?.Departure?.time.slice(0, -3)}
          </span>
        </div>

        <div className="flex flex-col items-center">
          <div className="flex items-center">
            <span className="mr-2 h-[2px] w-10 rounded-full bg-main-700"></span>
            <span className="text-xs text-gray-600">{seg?.duration}</span>
            <span className="ml-2 h-[2px] w-10 rounded-full bg-main-700"></span>
          </div>
        </div>

        <div className="flex flex-col items-end">
          <div className="flex items-center">
            <FaPlaneArrival className="mr-2 text-green-500 w-4 h-4" />
            <span className="text-base font-medium text-gray-900">
              {seg?.Arrival?.location}
            </span>
          </div>
          <span className="text-xs text-gray-600">
            {moment(seg?.Arrival.date).format("ddd, MMM D")} at{" "}
            {seg?.Arrival?.time.slice(0, -3)}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-FlightCardgroup relative overflow-hidden rounded-2xl border border-neutral-100 bg-white shadow transition-shadow hover:shadow-lg space-y-6${className} `}
      data-nc-id="FlightCard"
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className="border-b border-slate-200 px-4 py-2 leading-8">
        <div className="flex gap-4 items-center">
          <img
            src={getCarrierLogo(getAirSegment(id)?.carrier)}
            className="w-12 h-8 object-contain"
            alt=""
          />
          <h1>{getCarrierInfo(getAirSegment(id)?.carrier).Name}</h1>
        </div>
      </div>

      <div className="flex flex-col md:flex-row justify-between items-center mx-auto container gap-1 w-full relative">
        <div className="px-4 py-2 space-y-3 md:w-2/3 ">
          {flight?.ProductOptions.map((segment, index) =>
            segment?.Product?.[0]?.FlightSegment.map((item, i) => {
              const seg = getAirSegment(item?.Flight.FlightRef);

              return (
                <div
                  key={i}
                  className="flex  flex-col md:flex-row justify-between w-full px-5 pb-1 sm:pr-12"
                  data-nc-id="FlightCard"
                >
                  <div
                    className={`flex flex-col space-y-6 w-full ${
                      segment.length > 1
                        ? "mt-2 sm:items-center"
                        : "sm:items-center"
                    } md:space-y-0 xl:flex-row`}
                  >
                    {renderOneWay(seg)}
                  </div>
                  <div className=" flex-col justify-center items-end hidden xl:flex ">
                    <div className="mx-auto max-w-lg sm:px-0 md:px-4"></div>
                    <div className="flex justify-center gap-5 whitespace-nowrap sm:text-right">
                      <div className="text-xs sm:text-sm text-neutral-500 font-normal">
                        Flight- {seg?.number}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
        <div className="flex items-center md:mr-3 ">
          <span className="text-xl font-semibold text-secondary-6000 w-full">
            {getTotalPrice()} {price?.CurrencyCode?.value}
          </span>
        </div>

        <div className="items-center md:mr-24">
          <button
            className="text-sm px-3 py-2 bg-main text-white rounded-xl"
            onClick={() => {
              onSelect(flight);
              // addSelectedFlight(flight);
              // setFlightIndex(1);
            }}
          >
            Select
          </button>
        </div>

        <span
          className={`absolute right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 mx-4 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${
            isOpen ? "transform -rotate-180" : ""
          }`}
        >
          <i className="text-xl las la-angle-down"></i>
        </span>
      </div>

      {renderDetail()}
    </div>
  );
};

export default ExchangeFlights;
