//default base url

// const API_URL = "http://localhost:5000/api/v1/";
// const API_URL =
// "https://53m3372g28.execute-api.us-east-1.amazonaws.com/api/v1/";

//Development Api
const API_URL = "https://dev.sterlingagentportal.com/api/v1/";
// "https://fy4090fdz5.execute-api.us-east-1.amazonaws.com/api/v1/";
// "https://g4ynpjod42.execute-api.us-east-1.amazonaws.com/api/v1/";
// const API_URL = "https://devsterling-api.cyclic.app/api/v1/";

//Production Api
// const API_URL = "https://airflight-api.onrender.com/api/v1/";

export default API_URL;
