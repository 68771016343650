import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Loading from "../Loading";
import Locals from "../../utils/localFlights";
import ExchangeFlights from "./ExchangeFlights";
import {
  exchangeSearch,
  modifyOffer,
} from "../../redux2/exchange/exchangeSlice";
import {
  TRIP_TYPE_ONE_WAY,
  TRIP_TYPE_ROUND_TRIP,
  USER_ROLE_AGENT,
  USER_ROLE_EMPLOYEE,
  USER_ROLE_MEMBER,
  USER_ROLE_SUPERAGENT,
} from "../../common/constants";
import { getCompanyById, getMyCompany } from "../../redux2/user/userSlice";
import PickDate from "../../Pages/Home/Components/PickDate";
import Heading2 from "../Heading/Headings2";
import moment from "moment";

const ModifyOffer = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [selectedFlight, setSelectedFlight] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  const { data, offer, isLoading } = useSelector((state) => state.exchange);
  const { company, isLoading: loading } = useSelector((state) => state.user);
  const { user } = useSelector((state) => state.auth);

  const query = new URLSearchParams(location.search);
  const workbench = query.get("workbench");

  const { booking, data: search, searchData } = location.state;

  let isLocal =
    Locals.includes(booking?.flight?.destination) &&
    Locals.includes(booking?.flight?.origin);

  const getExchangeSc = (company, isLocal) => {
    if (!company) return 0;
    return isLocal ? company.exchangeLocalSC : company.exchangeInternationalSC;
  };

  const exchangeSc = getExchangeSc(company, isLocal);

  useEffect(() => {
    dispatch(exchangeSearch(searchData));
  }, [dispatch, searchData]);

  useEffect(() => {
    if (
      (user?.role === USER_ROLE_SUPERAGENT ||
        user?.role === USER_ROLE_EMPLOYEE) &&
      booking?.company
    ) {
      dispatch(getCompanyById(booking?.company?._id));
    } else if (
      (user?.role === USER_ROLE_AGENT || user?.role === USER_ROLE_MEMBER) &&
      booking?.company
    ) {
      dispatch(getMyCompany());
    }
  }, [booking?.company, dispatch, user?.role]);

  const navigate = useNavigate();

  useEffect(() => {
    if (offer?.CatalogOfferingsAirChangeResponse?.Result?.Error) {
      toast.error(offer?.Result?.Error?.[0]?.Message);
    }
  }, [offer]);

  let segment =
    offer?.CatalogOfferingsAirChangeResponse?.ReferenceList?.[0]?.Flight;
  let flights =
    offer?.CatalogOfferingsAirChangeResponse?.CatalogOfferings?.CatalogOffering;

  const { FormOfPayment, Offer, Traveler, Identifier } = search?.Reservation;

  function offerIdentifier() {
    return Offer?.map((item) => {
      return {
        id: item?.id,
        ref: item?.id,
        ...item?.Identifier,
      };
    });
  }

  function getTraveler() {
    return Traveler?.map((item) => {
      return {
        id: item?.id,
        passengerTypeCode: item?.passengerTypeCode,
      };
    });
  }

  const onSelect = (flight) => {
    setSelectedFlight(flight);

    const request = {
      CatalogOfferingID:
        offer?.CatalogOfferingsAirChangeResponse?.CatalogOfferings?.["@type"],
      CatalogOfferingValue:
        offer?.CatalogOfferingsAirChangeResponse?.CatalogOfferings?.Identifier
          ?.value,
      id: flight?.id,
      WorkBench: workbench,
    };

    dispatch(modifyOffer(request));
  };

  if (data?.OfferListResponse?.Offer) {
    let req = {
      id: offer?.CatalogOfferingsAirChangeResponse?.CatalogOfferings?.Identifier
        ?.value,
      authority: Identifier.authority,
      value:
        offer?.CatalogOfferingsAirChangeResponse?.CatalogOfferings?.Identifier
          ?.value,

      amount: {
        code: offer?.CatalogOfferingsAirChangeResponse?.CatalogOfferings?.Amount
          ?.code,
        value:
          offer?.CatalogOfferingsAirChangeResponse?.CatalogOfferings?.Amount
            ?.value,
      },
      payment: data?.OfferListResponse?.Offer[0]?.Identifier,
      FormOfPaymentIdentifier: {
        id: FormOfPayment?.[0].id,
        ref: FormOfPayment?.[0].id,
        ...FormOfPayment?.[0].Identifier,
      },
      OfferIdentifier: offerIdentifier(),
      Traveler: getTraveler(),
      WorkBench: workbench,
    };

    navigate(`/exchange-payment?Locator=${booking?.flight?.providerPnr}`, {
      state: { booking, req, selectedFlight, segment, company, exchangeSc },
    });
  }

  if (data?.OfferListResponse?.Result?.Error) {
    toast.error(data?.OfferListResponse?.Result?.Error?.[0]?.Message);
  }

  const OD = searchData?.SearchCriteriaFlight;
  const origin = OD?.[0]?.From;
  const destination = OD?.[0]?.To;

  const queryParam = {
    startDate: new Date(searchData?.SearchCriteriaFlight?.[0]?.departureDate),
    endDate:
      searchData?.SearchCriteriaFlight?.[1] &&
      new Date(searchData?.SearchCriteriaFlight?.[1]?.departureDate),
    tripType:
      searchData?.SearchCriteriaFlight.length > 1
        ? TRIP_TYPE_ROUND_TRIP
        : TRIP_TYPE_ONE_WAY,
  };

  function searchFlight(search) {
    dispatch(exchangeSearch(search));
  }

  if (isLoading || loading) return <Loading />;

  return (
    <div className="">
      <div className="w-full flex flex-col justify-center items-center">
        <h1 className="text-4xl font-semibold my-2 text-center text-gray-800 w-1/2">
          Modify Offer
        </h1>

        {/* {data && */}
        {/* {data?.TicketChangeEligibility?.map((item) => ( */}
        <div className="w-1/2 mt-6">
          {/* <h1 className="text-xl font-medium mb-6 text-center text-gray-800">
            Modify Offer for Ticket No:{" "}
            <span className="underline italic">
              {booking?.flight?.ticketNumber}
            </span>
          </h1> */}
          <div className="flex flex-col justify-center items-center">
            {/* <div className="flex flex-row justify-between items-center w-full p-2 border-b-2 border-main-300">
              <h1 className="text-base  text-gray-800">Passenger Name</h1>
              <h1 className="text-base font-semibold text-gray-800">
                {booking?.passengers?.[0]?.firstName +
                  " " +
                  booking?.passengers?.[0]?.lastName}
              </h1>
            </div> */}
            {/* <div className="flex flex-row justify-between items-center w-full p-2 border-b-2 border-main-300">
              <h1 className="text-lg  text-gray-800">Exchange Status</h1>
              <h1 className="text-lg font-semibold text-gray-800">
                Yes
              </h1>
            </div>
            <div className="flex flex-row justify-between items-center w-full p-2 border-b-2 border-main-300">
              <h1 className="text-lg  text-gray-800">Passenger Type</h1>
              <h1 className="text-lg font-semibold text-gray-800">
                ADT
              </h1>
            </div>
            
            <div className="flex flex-row justify-between items-center w-full p-2 border-b-2 border-main-300">
              <h1 className="text-lg text-gray-800">Penalty</h1>
              <h1 className="text-lg font-semibold text-gray-800">
                500
              </h1>
            </div> */}
          </div>
        </div>
      </div>
      {/* ))} */}

      <div
        className={`nc-SectionGridFilterCard mt-3 px-4 lg:px-0 lg:mt-4 pb-24 lg:pb-10 lg:mx-40`}
        data-nc-id="SectionGridFilterCard"
      >
        <div className="flex justify-between">
          <Heading2
            heading={
              <div>
                Flights from{" "}
                <span className="text-main">{`${origin} to ${destination}`}</span>
                <span className="text-main text-xl mx-2">
                  {" "}
                  {moment(
                    new Date(segment?.[0]?.Departure?.date),
                    "ddd, MMM D"
                  ).format("ll")}{" "}
                  {/* {OD?.length > 1 &&
                  " - " + moment(new Date(OD?.[1]?.departureDate)).format("ll")} */}
                </span>
              </div>
            }
            subHeading={
              <div className="block text-neutral-500 dark:text-neutral-400 mt-3">
                {flights?.length} Flights
                <span className="mx-2">·</span>
                {OD?.length === 1 ? "One Way" : "Round Trip"}
                <span className="mx-2">·</span>{" "}
                {isLocal ? "Local" : "International"}
              </div>
            }
          />
          <div className="">
            <button
              className="bg-red-500 hover:bg-red-600 rounded-lg px-4 py-2 text-white font-bold"
              onClick={() => window.history.back()}
            >
              Go Back
            </button>
          </div>
        </div>

        <PickDate
          queryParam={queryParam}
          searchData={searchData}
          searchFlight={searchFlight}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          exchange={true}
        />
        <h1 className="text-2xl font-semibold mx-12 text-gray-800 ">
          Flight Result
        </h1>
        <div className="lg:p-10 grid grid-cols-1 gap-6 rounded-3xl">
          {flights?.length > 0 &&
            flights?.map((item, index) => (
              <ExchangeFlights
                key={item?._attributes?.Key}
                flight={item}
                segment={segment}
                setSelectedFlight={setSelectedFlight}
                selectedFlight={selectedFlight}
                onSelect={onSelect}
                index={index}
                Traveler={Traveler}
                exchangeSc={exchangeSc}
              />
            ))}
        </div>
      </div>

      {/* <div className="w-full p-4 flex justify-center mt-10 container">
        <Link
          className="bg-main text-white px-6 py-2 rounded-lg ml-2"
          to={`/exchange-search`}
        >
          Next
        </Link>
      </div> */}
      {/* add button to the right */}
    </div>
  );
};

export default ModifyOffer;
