import axios from "axios";
import API_URL from "../redux2";
import { toast } from "react-toastify";

const KachaLoanRequest = async ({
  phone,
  principal,
  code,
  pnr_number,
  setLoading,
}) => {
  if (!phone || !principal || !code || !pnr_number) return;

  setLoading(true);
  try {
    const { data } = await axios.post(API_URL + "booking/payment/kacha", {
      phone,
      principal,
      code,
      pnr_number,
    });
    return data;
  } catch (err) {
    console.log(err);
  } finally {
    setLoading(false);
  }
};

const KachaAuthorizeRepayment = async ({
  otp,
  reference,
  code,
  setIsLoading,
}) => {
  console.log(otp, reference, code);
  // try {
  //   setIsLoading(true);
  //   const request = {
  //     reference,
  //     code,
  //     otp,
  //   };
  //   await axios.post(`${API_URL}kacha/authorize-repayment`, request);
  // } catch (error) {
  //   console.log(error.response.data);

  //   let message =
  //     error.response.data.error.message || error.response.data.error;
  //   toast.error(message);
  // } finally {
  //   setIsLoading(false);
  // }
};

const requestLoan = async (kachaReq) => {
  const { data } = await axios.post(
    API_URL + "booking/payment/kacha",
    kachaReq
  );
  return data;
};

async function getLoanProduct(phone) {
  const { data } = await axios.get(API_URL + `kacha/product?phone=${phone}`);
  return data;
}

async function initiateLoanRequest(data, setLoading, setPayment) {
  setLoading(true);
  try {
    const res = await axios.post(API_URL + "booking/payment/initiate", data);

    setPayment(res?.data?.data);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
}

async function authorizeLoan(kacha, id, otp, setLoading) {
  try {
    setLoading(true);
    const request = {
      otp,
      reference: kacha?.reference,
      trace_number: kacha?.trace_number,
      code: kacha?.code,
    };

    const res = await axios.post(
      `${API_URL}kacha/authorize-loan/${id}`,
      request
    );

    window.location.reload();
    return res;
  } catch (error) {
    console.log(error);

    let message =
      error.response.data.error?.message || error.response.data.error;
    toast.error(message);
  } finally {
    setLoading(false);
    // dispatch(closeModal("Kacha"));
  }
}

export {
  KachaLoanRequest,
  KachaAuthorizeRepayment,
  requestLoan,
  getLoanProduct,
  initiateLoanRequest,
  authorizeLoan,
};
