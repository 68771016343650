import axios from "axios";

import API_URL from "../redux2";

const API_URL_EXCHANGE = API_URL + "exchange/";

const eligibility = async (token, setLoading, pnr) => {
  if (!token) return;

  setLoading(true);
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.get(
      API_URL_EXCHANGE + `eligibility?Locator=${pnr}`,
      config
    );

    return res.data?.TicketChangeEligibilityListResponse;
  } catch (err) {
    console.log(err);
  } finally {
    setLoading(false);
  }
};

const addPayment = async (request, setLoading) => {
  if (!request) return;

  const token = JSON.parse(localStorage.getItem("token"));

  setLoading(true);
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.post(
      API_URL_EXCHANGE + "add-payment",
      request,
      config
    );

    return res.data;
  } catch (err) {
    console.log(err);
  } finally {
    setLoading(false);
  }
};

export { addPayment, eligibility };
