const SelectSegment = ({ segments, selectedFlight, setSelectedFlight }) => {
  return (
    <div className="max-w-5xl w-full mx-auto">
      <form action="">
        {/* Select All Button */}
        <div className="flex items-center justify-between mb-2 rounded-md">
          <h5 className="text-base font-medium text-center text-gray-800">
            Select Flight to Exchange
          </h5>
          <div className="flex items-center">
            <input
              type="checkbox"
              id="select-all"
              checked={selectedFlight?.length === segments?.length}
              className="form-checkbox h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
              onChange={(e) => {
                const isChecked = e.target.checked;

                segments?.forEach((passenger, index) => {
                  const passengerCheckboxId = `exchange-segment-${index}`;
                  const passengerCheckbox =
                    document.getElementById(passengerCheckboxId);

                  if (passengerCheckbox) {
                    passengerCheckbox.checked = isChecked;
                  } else {
                    console.warn(
                      `Element with id "${passengerCheckboxId}" not found.`
                    );
                  }
                });

                setSelectedFlight(isChecked ? segments : []);
              }}
            />
            <label
              htmlFor="select-all"
              className="ml-3 block font-medium text-gray-700 cursor-pointer my-2 text-base"
            >
              Select All
            </label>
          </div>
        </div>
        {segments?.map((segment, i) => {
          const seg = segment?.FlightSegment;
          return (
            <div
              className="flex items-center justify-between mb-3 p-2 rounded-md bg-gray-100 hover:bg-gray-200 transition duration-150 ease-in-out"
              key={i}
            >
              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="exchange-segment"
                  id={`exchange-segment-${i}`}
                  className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                  value={segment.id}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedFlight([...selectedFlight, segment]);
                    } else {
                      setSelectedFlight(
                        selectedFlight?.filter((data) => data.id !== segment.id)
                      );
                    }
                  }}
                />
                <label
                  htmlFor={`exchange-segment-${i}`}
                  className="ml-3 block font-medium text-gray-700 cursor-pointer text-base mt-2"
                >
                  {seg[0]?.Flight?.Departure?.location} -{" "}
                  {seg.at(-1)?.Flight?.Arrival?.location}
                </label>
              </div>
              <h1>
                {seg[0]?.Flight?.Departure?.date} -{" "}
                {seg.at(-1)?.Flight?.Departure?.time.slice(0, -3)}
              </h1>
              <div className="text-gray-500">
                <h1>{seg[0]?.Flight?.carrier}</h1>
              </div>
            </div>
          );
          // });
        })}
      </form>
    </div>
  );
};

export default SelectSegment;
