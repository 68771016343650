import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { reset } from "../../redux2/exchange/exchangeSlice";

const Eligibility = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { booking, data } = location.state;

  useEffect(() => {
    if (data?.Result?.Error) {
      toast.error(data?.Result?.Error?.[0]?.Message);
      dispatch(reset());
    }
  }, [data, dispatch]);

  const handleNext = () => {
    navigate(`/exchange-search?Locator=${booking?.flight?.providerPnr}`, {
      state: { booking },
    });
  };

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <h1 className="text-2xl font-semibold mb-6 text-center text-gray-800 w-1/2">
        Check if you are eligible to exchange your ticket.
      </h1>

      {data &&
        data?.TicketChangeEligibility?.map((item) => (
          <div className="w-1/2 mt-6">
            <h1 className="text-xl font-medium mb-6 text-center text-gray-800">
              Eligibility Details for Ticket No:{" "}
              <span className="underline italic">
                {item?.Identifier?.value}
              </span>
            </h1>
            <div className="flex flex-col justify-center items-center">
              <div className="flex flex-row justify-between items-center w-full p-2 border-b-2 border-main-300">
                <h1 className="text-lg  text-gray-800">Exchange Status</h1>
                <h1 className="text-lg  text-gray-800">{item.exchangeable}</h1>
              </div>
              <div className="flex flex-row justify-between items-center w-full p-2 border-b-2 border-main-300">
                <h1 className="text-lg  text-gray-800">Passenger Type</h1>
                <h1 className="text-lg  text-gray-800">
                  {item.PassengerTypeCode}
                </h1>
              </div>
              <div className="flex flex-row justify-between items-center w-full p-2 border-b-2 border-main-300">
                <h1 className="text-lg text-gray-800">Penalty Status</h1>
                <h1 className="text-lg text-gray-800">
                  {item.Penalties?.Change?.map((pen) => (
                    <div>
                      <h1>{pen["@type"]}</h1>
                    </div>
                  ))}
                </h1>
                {/* <h1 className="text-lg text-gray-800">
                  {item.Penalties?.Change?.["@type"]?.[0]}
                </h1> */}
              </div>
              {/* <div className="flex flex-row justify-between items-center w-full p-2 border-b-2 border-main-300">
                <h1 className="text-lg text-gray-800">Penalty</h1>
                <h1 className="text-lg text-gray-800">
                  {item.Penalties?.Change?.map((pen) => (
                    <div>
                      <h1>{pen.penaltyTypes?.[0]}</h1>
                    </div>
                  ))}
                </h1>
                <h1 className="text-lg  text-gray-800">
                  {item.Penalties?.Change?.map((pen) => (
                    <div>
                      <h1>
                        {pen.Penalty?.[0]?.Amount?.value}{" "}
                        {pen.Penalty?.[0]?.Amount?.code}
                      </h1>
                    </div>
                  ))}
                </h1>
              </div> */}
            </div>
          </div>
        ))}
      {data && (
        <div className="w-full p-4 flex justify-center mt-10 container">
          <button
            className="bg-main text-white px-6 py-2 rounded-lg ml-2"
            onClick={handleNext}
          >
            Next
          </button>
        </div>
      )}

      {/* add button to the right */}
    </div>
  );
};

export default Eligibility;
