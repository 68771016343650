import React from "react";
import {
  convertToNumber,
  removeCurrency,
} from "../../../utils/convertToNumber";

const PassengerPricing = ({
  label,
  count,
  priceIndex,
  AirPricing,
  rateDifference,
}) => {
  const getTotalPrice = (index) =>
    AirPricing?.AirPricingInfo?._attributes?.TotalPrice ||
    AirPricing?.AirPricingInfo?.[index]?._attributes?.TotalPrice;

  const formatPrice = (price) => {
    if (rateDifference > 1) {
      const convertedPrice = (removeCurrency(price) * rateDifference).toFixed(
        2
      );

      return `${convertToNumber(convertedPrice)} ${price?.substring(0, 3)}`;
    }

    return `${convertToNumber(price)} ${price?.substring(0, 3)}`;
  };

  return (
    count > 0 && (
      <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
        <div>{`${count} * ${label}`}</div>
        <div>{formatPrice(getTotalPrice(priceIndex))}</div>
      </div>
    )
  );
};

export default PassengerPricing;
