import React, { useEffect, useMemo, useState } from "react";
import { Tab } from "react-bootstrap";
import { useSelector } from "react-redux";

import BookingStat from "./components/BookingStat";
import CustomDateSelector from "../../components/CustomDateSelector";

const GuestList = () => {
  const { user } = useSelector((state) => state.auth);

  const [rangeDate, setRangeDate] = useState([]);
  const [durationSelect, setDurationSelect] = useState("");

  const currentDate = new Date();
  let startDate, endDate;

  // console.log(rangeDate?.[1] === null, rangeDate?.[1]);
  useEffect(() => {
    if (rangeDate?.[1] !== undefined) {
      setDurationSelect("Custom");
    }
  }, [rangeDate]);

  switch (durationSelect) {
    case "This_Month":
      startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      endDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );
      startDate?.setHours(0, 0, 0, 0);
      endDate?.setHours(23, 59, 59, 999);
      break;
    case "Last_Month":
      startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 1,
        1
      );
      endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
      startDate?.setHours(0, 0, 0, 0);
      endDate?.setHours(23, 59, 59, 999);
      break;
    case "This_Week":
      const firstDayOfWeek = currentDate.getDate() - currentDate.getDay();
      startDate = new Date(currentDate.setDate(firstDayOfWeek));
      endDate = new Date(currentDate.setDate(firstDayOfWeek + 6));
      startDate?.setHours(0, 0, 0, 0);
      endDate?.setHours(23, 59, 59, 999);
      break;
    case "This_Year":
      startDate = new Date(currentDate.getFullYear(), 0, 1);
      endDate = new Date(currentDate.getFullYear(), 11, 31);
      startDate?.setHours(0, 0, 0, 0);
      endDate?.setHours(23, 59, 59, 999);
      break;
    case "Today":
      startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      );
      endDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        23,
        59,
        59
      );
      startDate?.setHours(0, 0, 0, 0);
      endDate?.setHours(23, 59, 59, 999);
      break;
    case "Custom":
      startDate = new Date(rangeDate?.[0] || null);
      endDate = new Date(rangeDate?.[1] || null);
      startDate?.setHours(0, 0, 0, 0);
      endDate?.setHours(23, 59, 59, 999);
      break;

    default:
      startDate = null;
      endDate = null;
      break;
  }

  const query = useMemo(() => {
    return {
      startDate: startDate ? startDate?.toISOString() : "",
      endDate: endDate ? endDate?.toISOString() : "",
    };
  }, [startDate, endDate]);

  return (
    <>
      <Tab.Container defaultActiveKey="All">
        <div className="row lg:mx-24 md:mx-16">
          <div className="col-xl-12">
            <CustomDateSelector
              rangeDate={rangeDate}
              setRangeDate={setRangeDate}
              setDurationSelect={setDurationSelect}
            />
          </div>
          <BookingStat user={user} query={query} />
        </div>
      </Tab.Container>
    </>
  );
};

export default GuestList;
