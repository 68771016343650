import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Tab } from "@headlessui/react";
import classNames from "classnames";
import { FaPlaneDeparture, FaPlaneArrival } from "react-icons/fa";
import { formatNumber } from "../../../utils/convertToNumber";
import { getPriceEach } from "../helpers";
import { ApplicationCode } from "../AirPricing/PricingReq";
import { IoWarning } from "react-icons/io5";

const MiniFareRuleModal2 = ({
  penalty,
  segment,
  offerPrice,
  getTravelerType,
  setPassengerIndex,
}) => {
  const [openRule, setOpenRule] = useState(false);
  const [segmentIndex, setSegmentIndex] = useState(0);

  const OD = offerPrice?.[0]?.RequestedDate?.Associations?.map((item) => {
    return item.ApplicableFlight.OriginDestinationReferences[0];
  });

  const handleShowFareRule = () => {
    setOpenRule(true);
  };

  function getAppCode(ref) {
    return ApplicationCode.find((item) => item.key === ref)?.title;
  }

  function getSegmentDetail(ref) {
    return segment.find((item) => item.OriginDestinationKey === ref);
  }

  return (
    <div>
      {/* <div
        className="dropdown-item cursor-pointer text-black"
        onClick={handleShowFareRule}
      >
        Mini Fare Rule

      </div> */}
      {/* <div className="flex justify-start">
        <button className="dropdown-item cursor-pointer text-black">
          <div className="flex items-center justify-center gap-1">
            <LuBaggageClaim className="h-4 w-4 text-red-500" />
            <span>Fare Rule</span>
          </div>
        </button>
      </div> */}
      <button
        // onClick={() => getPricing(offerInfo)}
        onClick={handleShowFareRule}
        className="rounded-lg px-6 py-2 text-black"
      >
        <div className="flex items-center justify-center gap-1">
          <IoWarning className="h-5 w-5 text-red-500" />
          <span>Mini Fare Rule</span>
        </div>
      </button>
      <div className="">
        <Modal
          show={openRule}
          className="modal fade"
          onHide={() => setOpenRule(false)}
          size="lg"
          centered
        >
          <Modal.Header closeButton className="bg-main">
            <Modal.Title className="text-xl text-white">
              Mini Fare Rule
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="flex justify-between gap-2 text-sm">
              <Tab.Group as="div" className="flex w-full gap-3">
                <Tab.List as="div" className="w-1/3 py-1 mt-14">
                  {OD?.map((item, index) => (
                    <Tab
                      key={index}
                      className={({ selected }) =>
                        classNames(
                          "w-full rounded-lg p-3 text-base font-medium leading-5",
                          "focus:outline-none",
                          selected
                            ? "text-main  border-1 border-main "
                            : "text-black hover:bg-white/[0.6] hover:text-gray-900"
                        )
                      }
                      onClick={() => setSegmentIndex(index)}
                    >
                      <div className="flex gap-2 items-center">
                        <div className="flex items-center gap-1">
                          <FaPlaneDeparture className=" text-blue-500 w-5 h-5" />
                          <span className="font-medium text-gray-900">
                            {getSegmentDetail(item)?.DepartureCode?.value}
                          </span>
                        </div>
                        {" - "}

                        <div className="flex items-center gap-1">
                          <FaPlaneArrival className=" text-green-500 w-5 h-5" />
                          <span className="font-medium text-gray-900">
                            {getSegmentDetail(item)?.ArrivalCode?.value}
                          </span>
                        </div>
                      </div>
                    </Tab>
                  ))}
                </Tab.List>

                <Tab.Panels as="div" className="flex flex-col w-full ">
                  <Tab.Group as="div" className="flex flex-col gap-3">
                    <Tab.List as="div" className="py-1 flex flex-row">
                      {getPriceEach(offerPrice)?.map((item, i) => (
                        <Tab
                          key={i}
                          className={({ selected }) =>
                            classNames(
                              "rounded-lg px-4 py-2 text-base font-medium leading-5",
                              "focus:outline-none flex text-white",
                              selected
                                ? "bg-main "
                                : " hover:bg-white/[0.6] hover:text-gray-900"
                            )
                          }
                          onClick={() => setPassengerIndex(i)}
                        >
                          <div className="flex gap-2 items-center">
                            <div className="flex items-center gap-1">
                              <span className="font-normal tracking-wider text-gray-900">
                                {getTravelerType(item.type)}
                              </span>
                            </div>
                          </div>
                        </Tab>
                      ))}
                    </Tab.List>
                    <Tab.Panels as="div" className="flex w-full">
                      {offerPrice?.map((_, index) => (
                        <Tab.Panel className="w-full" key={index}>
                          {penalty?.[segmentIndex]?.map((pen, index) => {
                            const currency =
                              pen.item.Details.Detail[0].Amounts?.Amount[0]
                                ?.CurrencyAmountValue?.Code;
                            const appCode =
                              pen.item.Details?.Detail[0]?.Application.Code;
                            return (
                              <div
                                key={index}
                                className="mb-3 flex w-full flex-col gap-1 rounded-md bg-sky-200 px-3 py-1 font-normal text-black"
                              >
                                <p className="font-medium text-red-500">
                                  {pen.type}
                                </p>
                                <p className="font-normal text-red-500">
                                  * {getAppCode(appCode)}
                                </p>
                                <ul className="mx-2 flex list-disc flex-col">
                                  <li>
                                    <p>
                                      Message: <b>{pen.message}</b>
                                    </p>
                                  </li>

                                  <li>
                                    <p>
                                      Min Amount:{" "}
                                      {pen?.hasMin
                                        ? `${currency} ${formatNumber(pen.min)}`
                                        : 0}
                                    </p>
                                    <p>
                                      Max Amount: {currency}{" "}
                                      {formatNumber(pen.max)}
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            );
                          })}
                        </Tab.Panel>
                      ))}
                    </Tab.Panels>
                  </Tab.Group>
                </Tab.Panels>
              </Tab.Group>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button
              type="button"
              className="rounded-lg bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-600"
              onClick={() => setOpenRule(false)}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default MiniFareRuleModal2;
