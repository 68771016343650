import axios from "axios";

import API_URL from "..";

const API_URL_BOOKING = API_URL + "booking/";

const createBooking = async (token, booking) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.post(API_URL_BOOKING + "/", booking, config);

  return res.data;
};

const updateBooking = async (token, booking) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.put(API_URL_BOOKING + "/", booking, config);

  return res.data;
};

const updateWallet = async (token, booking) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.put(API_URL_BOOKING + "/wallet", booking, config);

  return res.data;
};

const getBooking = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.get(API_URL_BOOKING + `/${id}`, config);

  return res.data;
};

const getBookingById = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.get(API_URL_BOOKING + `/booking/${id}`, config);

  return res.data;
};

const getBookings = async (token, query) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.get(
    API_URL_BOOKING +
      `?startDate=${query.startDate}&endDate=${query.endDate}&page=${query.page}&status=${query.status}&search=${query.search}&state=${query.state}&payment=${query.payment}&limit=${query.limit}`,
    config
  );

  return res.data;
};

const getBookingsByCompany = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.get(API_URL_BOOKING + "/company", config);

  return res.data;
};

const getCompanyBookings = async (token, query) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.get(
    API_URL_BOOKING +
      `/company-booking?startDate=${query.startDate}&endDate=${query.endDate}&page=${query.page}&status=${query.status}&search=${query.search}&state=${query.state}&payment=${query.payment}&limit=${query.limit}`,
    config
  );

  return res.data;
};

const getBookingByUser = async (token, query) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.get(
    API_URL_BOOKING +
      `/user?startDate=${query.startDate}&endDate=${query.endDate}&page=${query.page}&status=${query.status}&search=${query.search}&state=${query.state}&payment=${query.payment}&limit=${query.limit}`,
    config
  );

  return res.data;
};

const updateBookingRequestStatus = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.put(
    API_URL_BOOKING + `/request-status/${data.id}`,
    data,
    config
  );

  return res.data;
};

const apiBooking = {
  createBooking,
  getBooking,
  getBookings,
  updateWallet,
  updateBooking,
  getCompanyBookings,
  getBookingsByCompany,
  getBookingById,
  getBookingByUser,
  updateBookingRequestStatus,
};

export default apiBooking;
