import axios from "axios";
import API_URL from "../../../redux2";
import { NDCShoppingReq } from "../AirShopping/ShoppingReq";

export const getNDCAirShopping = async (searchReq, setLoading) => {
  const { segments, passengers, CabinClass, airline } = searchReq;
  const { Origin, Destination, PreferredTime } = segments[0];

  let returnDate = null;
  if (segments[1]) {
    returnDate = segments[1].PreferredTime;
  }

  let ADT = 0,
    LBR = 0,
    CHD = 0,
    INF = 0;
  passengers.map((passenger) => {
    if (passenger.Code === "ADT") {
      ADT = +passenger.Quantity || 0;
    }
    if (passenger.Code === "LBR") {
      LBR = +passenger.Quantity || 0;
    }
    if (passenger.Code === "CNN") {
      CHD = +passenger.Quantity || 0;
    }
    if (passenger.Code === "INF") {
      INF = +passenger.Quantity || 0;
    }
  });

  let req = {
    origin: Origin,
    destination: Destination,
    departureDate: PreferredTime,
    returnDate: returnDate ? returnDate : null,
    ...(CabinClass && { cabin: CabinClass === "Economy" ? "Y" : "C" }),
    // cabin: "F",
    adults: ADT,
    chd: CHD,
    inf: INF,
    labor: LBR,
  };

  console.log(req);

  const shop = NDCShoppingReq(req);
  console.log(shop);

  let header = {
    service: "AirShopping",
    ThirdpartyId: airline,
  };

  // console.log(JSON.stringify(shop, null, 2));

  try {
    setLoading(true);
    const res = await axios.post(API_URL + "/ndc", { shop, header });
    return res.data;
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};
